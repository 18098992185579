import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIntl } from "react-intl";
import { Parser } from "html-to-react";
import InfoIcon from "@mui/icons-material/Info";
import { Grid } from "@mui/material";
import useLang from "hooks/useLang";
import {
  AcordionStyled,
  AccordionSummaryStyled,
  TypographyStyled,
  AccordionDetailsStyled,
  Info,
} from "components/SectionAccordion/style";

const SectionAccordion = ({ section, children }) => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md")); // 900
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm")); // 600
  const translate = useIntl();
  const { getEstimatorTemplateLanguageText } = useLang();
  const { subsections } = section;

  let getFontSize = () => {
    if (matchesSM) {
      // 600
      return "14px";
    }
    if (matchesMD) {
      // 900
      return "18px";
    }
    return "24px";
  };
  return (
    <>
      <AcordionStyled key={section.id}>
        <AccordionSummaryStyled expandIcon={<InfoIcon />}>
          <TypographyStyled style={{ fontSize: getFontSize() }}>
            {getEstimatorTemplateLanguageText(section)}
          </TypographyStyled>
        </AccordionSummaryStyled>
        <AccordionDetailsStyled>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            {section.id === 1 && <Info item>{Parser().parse(translate.formatMessage({ id: "info.exw" }))}</Info>}
            {section.id === 2 && (
              <Info item>
                {Parser().parse(translate.formatMessage({ id: "info.fca" }))}
                <hr />
                {Parser().parse(translate.formatMessage({ id: "info.fas" }))}
                <hr />
                {Parser().parse(translate.formatMessage({ id: "info.fob" }))}
              </Info>
            )}
            {section.id === 3 && (
              <Info item>
                {Parser().parse(translate.formatMessage({ id: "info.cfr" }))}
                <hr />
                {Parser().parse(translate.formatMessage({ id: "info.cpt" }))}
              </Info>
            )}
            {section.id === 4 && (
              <Info item>
                {Parser().parse(translate.formatMessage({ id: "info.cip" }))}
                <hr />
                {Parser().parse(translate.formatMessage({ id: "info.cif" }))}
              </Info>
            )}
            {section.id === 5 && <Info item>{Parser().parse(translate.formatMessage({ id: "info.dap" }))}</Info>}
            {section.id === 6 && <Info item>{Parser().parse(translate.formatMessage({ id: "info.dpu" }))}</Info>}
            {section.id === 7 && <Info item>{Parser().parse(translate.formatMessage({ id: "info.ddp" }))}</Info>}
          </Grid>
        </AccordionDetailsStyled>
      </AcordionStyled>
      {children(subsections)}
      <br />
      <br />
    </>
  );
};

export default SectionAccordion;
