import React from "react";
import { Link, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Parser } from "html-to-react";
import useLang from "hooks/useLang";

export default function QuestionHelp({ question, pRight = 0 }) {
  const { getTextByLanguage } = useLang();
  return (
    question.hasHelp && (
      <Tooltip
        title={Parser().parse(
          getTextByLanguage(
            question.spanishHelpDescription,
            question.portugueseHelpDescription,
            question.englishHelpDescription
          )
        )}
        placement="left"
        leaveTouchDelay={3000}
        enterTouchDelay={50}
      >
        <InfoIcon
          sx={{
            width: "30px",
            height: "23px",
            color: "rgba(0, 0, 0, 0.54)",
          }}
        />
      </Tooltip>
    )
  );
}
