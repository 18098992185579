import React, { useState } from "react";
import { useIntl } from "react-intl";

import ButtonContained from "components/ButtonContained";
import useUser from "hooks/useUser";
import LoginModal from "components/LoginModal";

const AddEstimation = () => {
  const translate = useIntl();
  const { isLogged } = useUser();
  const [openLoginModal, setOpenLoginModal] = useState(false);

  if (!isLogged) {
    return (
      <>
        <LoginModal open={openLoginModal} handleClose={() => setOpenLoginModal(false)} />
        <ButtonContained
          componentwidth="416px"
          text={translate.formatMessage({ id: "button.calculate" })}
          onClick={() => {
            setOpenLoginModal(true);
          }}
        />
      </>
    );
  }
  return null;
};
export default AddEstimation;
