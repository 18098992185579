import { TextField, Grid } from "@mui/material";
import styled from "@emotion/styled";

export const TexFieldEstimatorStyled = styled(TextField)`
  @media (max-width: 600px) {
    width: 90%;
  }
  width: 120px;
  input {
    color: ${(props) => props.fontColor};
  }
`;

export const LineQuestionStyles = styled.hr`
  border: 1px solid #d9d9d9;
  background-color: #d9d9d9;
  margin: 0;
`;

export const ValueGrid = styled(Grid)`
  height: 52px;
`;

export const TotalValue = styled.div`
  float: left;
  width: 50%;
`;

export const PercentValueGrid = styled(Grid)`
  background-color: rgba(46, 174, 193, 0.34);
  height: 52px;
`;
