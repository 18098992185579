import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from "@mui/material/Modal";
import "components/LoginModal/style.css";
import { URLs } from "services/settings";
import useAnalyticsEventTracker from "utils/useAnalyticsEventTracker";
import useUser from "hooks/useUser";

export default function BasicModal({ open, handleClose }) {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const gaEventTracker = useAnalyticsEventTracker("user_action");
  const { login, isLogged } = useUser();
  const serviceUrlObject = new URL(decodeURIComponent(URLs.serviceUrlAuth));

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.origin === serviceUrlObject.origin) {
        if (e.data && typeof e.data === "string") {
          login(e.data);
          // gaEventTracker("user_login", " user login");
          const user = JSON.parse(e.data);
          // ReactGA.set({ userId: user.email });
          handleClose();
        }
      }
    });
  }, []);

  useEffect(() => {
    if (isLogged) {
      handleClose();
    }
  }, [isLogged]);

  return (
    <Modal
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div id="main" className="mainregister" xmlns="http://www.w3.org/1999/html">
        <div className="overlay">&nbsp;</div>

        <div id="header" className="user-register-page-footer">
          <a href="https://google.com" className="close-modal" title="Inicio" id="logo">
            <img src="" alt="Inicio" />
          </a>
          {/* <div className="login">
            <a className="btn signup" href="/es/user/register" title="Únete ahora">
              Únete ahora
            </a>¿Olvidaste tu contraseña? - ¿No tienes una cuenta?Regístrate aquí
          </div> */}
        </div>

        <h2 className="user-register-title">Inicia sesión en ConnectAmericas</h2>

        <div id="content-wrapper" className="user-register-page-footer">
          <div id="content">
            <div className="separator-line">&nbsp;</div>

            <div className="gigya-screen-content gigya-screen-dialog-content" style={{ height: "auto", width: "100%" }}>
              <iframe
                id="login-iframe"
                title="Login"
                src={`${URLs.casUrlAuth}?service=${URLs.serviceUrlAuth}`}
                style={{ minHeight: "408px", overflow: "hidden", height: "408px", width: "100%" }}
              />
            </div>
          </div>
        </div>

        <div className="terms">
          <p>
            Al crear una cuenta en ConnectAmericas, aceptas
            <br /> las{" "}
            <a href="/es/content/políticas-de-privacidad" target="_blank">
              {" "}
              Políticas de Privacidad{" "}
            </a>{" "}
            y los{" "}
            <a href="/es/content/términos-y-condiciones" target="_blank">
              {" "}
              Términos y Condiciones
            </a>{" "}
          </p>
        </div>
      </div>
    </Modal>
  );
}
