import React from "react";
import { Snackbar, Alert, AlertTitle } from "@mui/material";

const SnackbarSuccess = ({ messageSuccess, open, onCloseHandler }) => {
  <Snackbar open={open} autoHideDuration={4000} onClose={onCloseHandler}>
    <Alert elevation={6} severity="success" variant="filled" onClose={onCloseHandler} sx={{ width: "100%" }}>
      <AlertTitle> {messageSuccess}</AlertTitle>
    </Alert>
  </Snackbar>;
};

export default SnackbarSuccess;
