import { TextField } from "@mui/material";
import styled from "@emotion/styled";

export const TexFieldEstimatorStyled = styled(TextField)`
  @media (max-width: 600px) {
    width: 90%;
  }
  width: 120px;
  input {
    color: ${(props) => props.fontColor};
  }
`;

export const LineQuestionStyles = styled.hr`
  border: 1px solid #d9d9d9;
  background-color: #d9d9d9;
  margin: 0;
`;
