import React, { useState } from "react";
import { useIntl } from "react-intl";
import ButtonContained from "components/ButtonContained";
import useUser from "hooks/useUser";
import AddModal from "components/AddModal";

const AddEstimation = ({ openAddModal, setOpenAddModal }) => {
  const translate = useIntl();

  const { isLogged } = useUser();

  if (isLogged) {
    return (
      <>
        <AddModal open={openAddModal} handleClose={() => setOpenAddModal(false)} />
        <ButtonContained
          componentwidth="416px"
          text={translate.formatMessage({ id: "button.save" })}
          onClick={() => setOpenAddModal(true)}
        />
      </>
    );
  }
  return null;
};
export default AddEstimation;
