import React from "react";
import { useIntl } from "react-intl";
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";

const ConfirmationDelete = ({ open, setOpen, entityName, onClickHandler }) => {
  const translate = useIntl();
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {translate.formatMessage({ id: "message.delete" }, { nameEstimation: entityName })}
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          autoFocus
        >
          {translate.formatMessage({ id: "button.cancel" })}
        </Button>
        <Button onClick={onClickHandler}>{translate.formatMessage({ id: "button.accept" })}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDelete;
