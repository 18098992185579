import React, { useState, useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { useFormikContext } from "formik";
import getEstimatorTemplate from "services/getEstimatorTemplate";
import GlobalContext from "contexts/GlobalContext";
import ButtonContained from "components/ButtonContained";
import ExportPdfModal from "components/ExportPdfModal";
import useUser from "hooks/useUser";
import useLang from "hooks/useLang";

const ExportPdf = () => {
  const { isLogged } = useUser();
  const { updateMode, setExportToPdf, setReadyToExportPdf, readyToExportPdf, estimation } = useContext(GlobalContext);
  const translate = useIntl();
  const { getEstimatorTemplateLanguageText, locale } = useLang();
  const [open, setOpen] = useState(false);
  const { submitForm } = useFormikContext();
  const [estimatorTemplate, setEstimatorTemplate] = useState([]);
  // const [trlst, setTrlst] = useState();

  useEffect(() => {
    if (readyToExportPdf) {
      getEstimatorTemplate().then((result) => {
        // setTrlst(translate);
        setEstimatorTemplate(result);
        setReadyToExportPdf(false);
        setOpen(true);
      });
    }
  }, [readyToExportPdf]);

  return (
    <>
      {isLogged && updateMode && (
        <ExportPdfModal
          open={open}
          handleClose={() => setOpen(false)}
          estimatorTemplate={estimatorTemplate}
          translate={translate}
          getEstimatorTemplateLanguageText={getEstimatorTemplateLanguageText}
          locale={locale}
          estimation={estimation}
        />
      )}
      {isLogged && updateMode && (
        <ButtonContained
          text={translate.formatMessage({ id: "button.exportPdf" })}
          onClick={() => {
            setExportToPdf(true);
            submitForm();
          }}
        />
      )}
    </>
  );
};
export default ExportPdf;
