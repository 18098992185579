import axios from "axios";
import { URLs } from "./settings";

export default function getProduct(pSearch, pLanguage, pOffset, perPage) {
  return axios
    .post(
      `${URLs.apiUrl}/Products`,
      {
        search: pSearch,
        language: pLanguage,
        offset: pOffset,
        per_page: perPage,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          clientToken: URLs.caClientToken,
        },
      }
    )
    .then((res) => {
      if (res.status !== 200) throw new Error("Response is NOT ok");
      return res.data;
    });

  // debugger;
  // const formData = new FormData();
  // formData.append("search", search);
  // formData.append("language", language);
  // formData.append("offset", offset);
  // formData.append("per_page", perPage);

  // const apiURL = "https://stage-ws.connectamericas.com/apirest/v6/company/catalog/search";
  // // ;
  // return fetch(apiURL, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/x-www-form-urlencoded",
  //     clientToken: URLs.caClientToken,
  //   },
  //   referrerPolicy: "no-referrer",
  //   body: formData,
  // })
  //   .then((res) => {
  //     if (!res.ok) throw new Error("Response is NOT ok");
  //     return res.json();
  //   })
  //   .then((res) => res);
}
