import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "@emotion/styled";
import footerImg from "images/footer-img.png";
import useLang from "hooks/useLang";
import ContactUsDialog from "components/ContactUsDialog";
import "components/Footer/style.css";

const Background = styled.div`
  width: 100%;
  background-color: #ffff;
  background-position: center;
  background-size: cover;
  height: 387px;
`;

export default function Footer() {
  const theme = useTheme();
  const matches900px = useMediaQuery("(max-width:900px)");
  const { setLanguage, locale } = useLang();
  const translate = useIntl();
  const [openContactUsDialog, setOpenContactUsDialog] = useState(false);

  return (
    <>
      <ContactUsDialog
        open={openContactUsDialog}
        setOpen={setOpenContactUsDialog}
        onClickHandler={() => {
          setOpenContactUsDialog(false);
        }}
      />
      <Background style={{ backgroundImage: `url(${footerImg})` }} />
      {!matches900px && (
        <div id="footer">
          <div id="footer-top">
            <div id="block-menu-footer-menu" className="block">
              <div className="block-content">
                <ul className="menu">
                  <li>
                    <a href={translate.formatMessage({ id: "link.aboutConnectAmericas" })}>
                      {translate.formatMessage({ id: "button.aboutConnectAmericas" })}
                    </a>
                  </li>
                  <li>
                    <a href={translate.formatMessage({ id: "link.termsAndConditions" })}>
                      {translate.formatMessage({ id: "button.termsAndConditions" })}
                    </a>
                  </li>
                  <li>
                    <a href={translate.formatMessage({ id: "link.privacyPolicy" })}>
                      {translate.formatMessage({ id: "button.privacyPolicy" })}
                    </a>
                  </li>
                  <li>
                    <a href={translate.formatMessage({ id: "link.helpCenter" })}>
                      {translate.formatMessage({ id: "button.helpCenter" })}
                    </a>
                  </li>
                  <li>
                    <a
                      className="md-trigger md-setperspective"
                      href="/"
                      title=""
                      onClick={(e) => {
                        setOpenContactUsDialog(true);
                        e.preventDefault();
                      }}
                    >
                      {translate.formatMessage({ id: "button.contactUs" })}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div id="social-networks" className="block static">
              <div className="block-title">
                <h2>{translate.formatMessage({ id: "footerText.followUsOn" })}</h2>
              </div>
              <div className="block-content">
                <ul className="menu">
                  <li>
                    <a
                      className="yt"
                      href="https://www.youtube.com/user/ConnectAmericas"
                      title="YouTube"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      YouTube
                    </a>
                  </li>
                  <li>
                    <a
                      className="fb"
                      href="https://www.facebook.com/ConnectAmericas"
                      title="Facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      className="ln"
                      href="https://www.linkedin.com/company/connectamericas"
                      title="Linkedin"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Linkedin
                    </a>
                  </li>
                  <li>
                    <a
                      className="tw"
                      href="https://twitter.com/connectamericas"
                      title="Twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="footer-bottom">
            <div id="footer-info" className="block static">
              <a
                href={translate.formatMessage({ id: "link.idb" })}
                target="_blank"
                rel="noopener noreferrer"
                id="footer-idblogo"
                className="block-content lang-es"
              >
                <p>
                  1300 New York Avenue, N.W. Washington, D.C. 20577, USA
                  <br />© 2015 Inter-American Development Bank
                </p>
              </a>
            </div>

            <div id="footer-supporters" className="block static">
              <div className="block-content">
                <span>{translate.formatMessage({ id: "footerText.withTheSupportOf" })}</span>
                <a className="google" href="http://www.google.com" target="_blank" rel="noopener noreferrer">
                  Google
                </a>
                <a
                  className="dhl"
                  href={translate.formatMessage({ id: "link.dhl" })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DHL
                </a>
                <a className="sealand" href="http://www.sealand.com" target="_blank" rel="noopener noreferrer">
                  SeaLand
                </a>
                <a className="facebook" href="http://www.facebook.com" target="_blank" rel="noopener noreferrer">
                  Facebook
                </a>
                <a className="master-card" href="http://www.mastercard.com" target="_blank" rel="noopener noreferrer">
                  MasterCard
                </a>
                <span className="created">{translate.formatMessage({ id: "footerText.createdBy" })}</span>
                <a
                  className="idb lang-es"
                  href={translate.formatMessage({ id: "link.idb" })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IDB
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      {matches900px && (
        <footer id="">
          <div id="footer-top">
            <div className="menu">
              <ul>
                <li>
                  <a href={translate.formatMessage({ id: "link.aboutConnectAmericas" })}>
                    {translate.formatMessage({ id: "button.aboutConnectAmericas" })}
                  </a>
                </li>
                <li>
                  <a href={translate.formatMessage({ id: "link.termsAndConditions" })}>
                    {translate.formatMessage({ id: "button.termsAndConditions" })}
                  </a>
                </li>
                <li>
                  <a href={translate.formatMessage({ id: "link.privacyPolicy" })}>
                    {translate.formatMessage({ id: "button.privacyPolicy" })}
                  </a>
                </li>
                <li>
                  <a href={translate.formatMessage({ id: "link.helpCenter" })}>
                    {translate.formatMessage({ id: "button.helpCenter" })}
                  </a>
                </li>
                <li>
                  <a
                    className="md-trigger "
                    href="/"
                    title=""
                    onClick={(e) => {
                      setOpenContactUsDialog(true);
                      e.preventDefault();
                    }}
                  >
                    {translate.formatMessage({ id: "button.contactUs" })}
                  </a>
                </li>
              </ul>
            </div>

            <div className="social" style={{ paddingBottom: "100px" }}>
              <span>{translate.formatMessage({ id: "footerText.followUsOn" })}</span>
              <div className="middle-social">
                <ul className="menu">
                  <li>
                    <a
                      className="yt"
                      href="https://www.youtube.com/user/ConnectAmericas"
                      title="YouTube"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      YouTube
                    </a>
                  </li>
                  <li>
                    <a
                      className="fb"
                      href="https://www.facebook.com/ConnectAmericas"
                      title="Facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      className="ln"
                      href="https://www.linkedin.com/company/connectamericas"
                      title="Linkedin"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Linkedin
                    </a>
                  </li>
                  <li>
                    <a
                      className="tw"
                      href="https://twitter.com/connectamericas"
                      title="Twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      className="int"
                      href="https://www.instagram.com/connectamericas"
                      title="Instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="footer-new-home">
            <div id="footer-bottom">
              <div id="footer-info" className="block static" style={{ height: "45px" }}>
                <a
                  href="http://www.iadb.org/es/"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="footer-idblogo"
                  className="block-content lang-es"
                >
                  <p>
                    1300 New York Avenue, N.W. Washington, D.C. 20577, USA
                    <br />© 2015 Inter-American Development Bank
                  </p>
                </a>
              </div>

              <div id="footer-supporters" style={{ height: "auto" }} className="block static">
                <div
                  className="block-content"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      padding: "4px 0",
                    }}
                  >
                    <span
                      style={{
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      {translate.formatMessage({ id: "footerText.withTheSupportOf" })}
                    </span>
                    <a className="google" href="http://www.google.com" target="_blank" rel="noopener noreferrer">
                      Google
                    </a>
                    <a
                      className="dhl"
                      href={translate.formatMessage({ id: "link.dhl" })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      DHL
                    </a>
                    <a className="sealand" href="http://www.sealand.com" target="_blank" rel="noopener noreferrer">
                      SeaLand
                    </a>
                    <a className="facebook" href="http://www.facebook.com" target="_blank" rel="noopener noreferrer">
                      Facebook
                    </a>
                    <a
                      className="master-card"
                      href="http://www.mastercard.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MasterCard
                    </a>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      padding: "4px 0",
                    }}
                  >
                    <span
                      style={{
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      <span className="created">{translate.formatMessage({ id: "footerText.createdBy" })}</span>
                    </span>
                    <a
                      className="idb lang-es"
                      href={translate.formatMessage({ id: "link.idb" })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      IDB
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
}
