import React from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";

const NonInfoMessage = ({ width }) => {
  const translate = useIntl();
  return (
    <Box width={width} style={{ "text-align": "center" }}>
      <Typography variant="h6">{translate.formatMessage({ id: "message.nonInfo" })}</Typography>
    </Box>
  );
};

export default NonInfoMessage;
