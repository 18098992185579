import React, { useState } from "react";
import { useIntl } from "react-intl";
import { URLs } from "services/settings";
import LoginModal from "components/LoginModal";

const LoginNavbar = () => {
  const translate = useIntl();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  return (
    <>
      <LoginModal open={openLoginModal} handleClose={() => setOpenLoginModal(false)} />
      <div>
        <a
          className="login"
          id="loginBtn"
          title={translate.formatMessage({ id: "button.signin" })}
          href="#"
          onClick={(e) => {
            setOpenLoginModal(true);
            e.preventDefault();
          }}
        >
          {translate.formatMessage({ id: "button.signin" })}
        </a>
      </div>
      <div>
        <a
          className="signup"
          style={{ color: "white !important" }}
          href={URLs.registerUserUrl}
          title={translate.formatMessage({ id: "button.join" })}
        >
          {translate.formatMessage({ id: "button.join" })}
        </a>
      </div>
    </>
  );
};

export default LoginNavbar;
