import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid, Box, Stepper, StepConnector, stepConnectorClasses, Step, StepLabel } from "@mui/material";
import FlightIcon from "@mui/icons-material/Flight";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styled from "@emotion/styled";
import GlobalContext from "contexts/GlobalContext";
import useLang from "hooks/useLang";

const StepperContainer = styled(Grid)`
  width: 1101px;
  height: 79px;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  font-size: 14px;
`;

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#BDBDBD",
    borderTopWidth: "2px",
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.primary.main,
  ...(ownerState.active && {
    color: "black",
  }),
}));

function CustomStepIcon(props) {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md")); // 900
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm")); // 600
  const { active, completed, className, icon } = props;
  const { nextActiveStep, setNextActiveStep } = useContext(GlobalContext);

  let getCheckIconSize = () => {
    if (matchesSM) {
      return "14px";
    }
    // if (matchesMD) {
    //   return "17px";
    // }
    return "22px";
  };

  let getFlightconSize = () => {
    if (matchesSM) {
      return "18px";
    }
    // if (matchesMD) {
    //   return "22px";
    // }
    return "25px";
  };

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed && (
        <CheckCircleIcon
          sx={{
            width: getCheckIconSize(),
            height: getCheckIconSize(),
            marginTop: "6px",
            color: "#2EAEC1",
          }}
        />
      )}
      {nextActiveStep === icon && (
        <FlightIcon
          sx={{
            transform: "rotate(90deg)",
            width: getFlightconSize(),
            height: getFlightconSize(),
            marginTop: "6px",
            color: "black",
          }}
        />
      )}
    </QontoStepIconRoot>
  );
}

export default function GroupSteps({ groups, children }) {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md")); // 900
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm")); // 600

  const { activeStep, setActiveStep } = useContext(GlobalContext);
  const { completedGroups, setCompletedGroups } = useContext(GlobalContext);
  const { getEstimatorTemplateLanguageText } = useLang();
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  let getFontSize = () => {
    if (matchesSM) {
      // 600
      return "10px";
    }
    // if (matchesMD) {
    //   // 900
    //   return "12px";
    // }
    return "14px !important";
  };

  let getLineHeight = () => {
    if (matchesSM) {
      // 600
      return "10px";
    }
    // if (matchesMD) {
    //   // 900
    //   return "12px";
    // }
    return "14px !important";
  };

  return (
    <>
      <Grid container justifyContent="center">
        <StepperContainer container sm={12} md={10} lg={10} justifyContent="center">
          <div style={{ width: matchesMD ? "auto" : "750px" }}>
            <Stepper nonLinear activeStep={activeStep - 1} connector={<QontoConnector />}>
              {groups.map((group, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step
                    key={group.id}
                    completed={completedGroups.includes(group.id)}
                    {...stepProps}
                    sx={{
                      cursor: "pointer",
                      "& .MuiStepLabel-root .Mui-active": {
                        color: "#75AAD4",
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <StepLabel
                      {...labelProps}
                      StepIconComponent={CustomStepIcon}
                      onClick={handleStep(index + 1)}
                      sx={{
                        "& .MuiStepLabel-label": {
                          fontSize: getFontSize(),
                          lineHeight: getLineHeight(),
                        },
                      }}
                    >
                      {getEstimatorTemplateLanguageText(group)}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </StepperContainer>
      </Grid>
      {groups.map(({ id, sections }, index) => {
        if (activeStep === index + 1) {
          return (
            <Grid container justifyContent="center">
              <Grid container direction="column" justifyContent="center" alignItems="center" sm={12} md={10}>
                <Box
                  style={{
                    paddingLeft: "0",
                    paddingRight: "0",
                    paddingBotton: "0",
                    paddingTop: "50px",
                  }}
                  sx={{ p: 3, width: "100%" }}
                >
                  {children(sections)}
                </Box>
              </Grid>
            </Grid>
          );
        }
        return null;
      })}
    </>
  );
}
