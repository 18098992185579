import React from "react";
import { useIntl } from "react-intl";
import { InputAdornment } from "@mui/material";
import { useFormikContext, Field } from "formik";
import { TextFieldStyled } from "components/InputDollarParity/style";
import ConvetToNumberType from "utils";
import { SetValuesQuestionPercentRow } from "components/InputPercent/helper";

const InputDollarParity = ({ rowsCount }) => {
  const translate = useIntl();
  const { values, setFieldValue } = useFormikContext();

  return (
    <Field
      autoFocus
      as={TextFieldStyled}
      label={translate.formatMessage({ id: "input.exchangeRate" })}
      variant="outlined"
      name="dollarParity"
      type="number"
      InputProps={{
        startAdornment: <InputAdornment position="start">1USD/ </InputAdornment>,
      }}
      inputProps={{
        min: 0,
        max: 999999999999,
      }}
      onFocus={(option) => {
        option.target.select();
      }}
      onBlur={(option) => {
        if (values.dollarParity === "") {
          setFieldValue("dollarParity", 0);
        }
      }}
      onChange={(option) => {
        let newCurrencyValues = [];
        let newTotals = [];
        const newParityValue =
          option.target.value === "" ? "" : ConvetToNumberType(option.target.value, 0, 999999999999, 3);

        setFieldValue("dollarParity", newParityValue);

        for (let i = 0; i < rowsCount; i++) {
          if (values[`qv_isTotal_${i}`]) {
            const indexFirstQuestionSubsection = values[`qv_indexFirstQuestionSubsection_${i}`];
            const indexTotalSubsection = values[`qv_indexTotalSubsection_${i}`];
            const totalRows = indexTotalSubsection - indexFirstQuestionSubsection;
            let totalDollarValue = 0;
            let totalLocalValue = 0;
            for (let x = 0; x <= totalRows - 1; x++) {
              totalDollarValue += newCurrencyValues[x].dollarValue;
              totalLocalValue += newCurrencyValues[x].localValue;
            }

            newTotals.push({
              indexTotalSubsection,
              totalDollarValue,
              totalLocalValue,
            });

            if (values[`qv_subsectionOperation_indexTotalSubsection_${indexTotalSubsection}`] != null) {
              const ssoIndexTotalSubsection =
                values[`qv_subsectionOperation_indexTotalSubsection_${indexTotalSubsection}`];
              const newDollarValue = newTotals.find(
                (x) => x.indexTotalSubsection === ssoIndexTotalSubsection
              ).totalDollarValue;
              const newLocalValue = newTotals.find(
                (x) => x.indexTotalSubsection === ssoIndexTotalSubsection
              ).totalLocalValue;
              if (values[`qv_subsectionOperation_operationType_${indexTotalSubsection}`] === 0) {
                totalDollarValue += newDollarValue;
                totalLocalValue += newLocalValue;
              } else {
                totalDollarValue = Number(newDollarValue) - totalDollarValue;
                totalLocalValue = Number(newLocalValue) - totalLocalValue;
              }

              newTotals = newTotals.filter((x) => x.indexTotalSubsection === ssoIndexTotalSubsection);
              newTotals.push({
                indexTotalSubsection,
                totalDollarValue,
                totalLocalValue,
              });
            }

            setFieldValue(`qv_dollarValue_${indexTotalSubsection}`, totalDollarValue);
            setFieldValue(`qv_localValue_${indexTotalSubsection}`, totalLocalValue);
            newCurrencyValues = [];
          } else if (values[`qv_isPercentage_${i}`]) {
            const ssoIndexTotalSubsection = values[`qv_subsectionOperation_indexTotalSubsection_${i}`];
            let totalValueRelated = newTotals.find((x) => x.indexTotalSubsection === ssoIndexTotalSubsection);
            const dollarValue = totalValueRelated
              ? (values[`qv_percentageValue_${i}`] * totalValueRelated.totalDollarValue) / 100
              : values[`qv_dollarValue_${i}`];
            const localValue = totalValueRelated
              ? (values[`qv_percentageValue_${i}`] * totalValueRelated.totalLocalValue) / 100
              : values[`qv_localValue_${i}`];

            SetValuesQuestionPercentRow(setFieldValue, i, values[`qv_percentageValue_${i}`], dollarValue, localValue);

            newCurrencyValues.push({
              dollarValue,
              localValue,
            });
          } else {
            let newLocalValue = values[`qv_localValue_${i}`];
            let newDollarValue = values[`qv_dollarValue_${i}`];

            if (values[`qv_colorChangeLocalValue_${i}`] !== "#2EAEC1") {
              newLocalValue = ConvetToNumberType(
                (newParityValue * values[`qv_dollarValue_${i}`]).toString(),
                0,
                9999999,
                2
              );
              setFieldValue(`qv_localValue_${i}`, newLocalValue);
            }

            if (values[`qv_colorChangeDollarValue_${i}`] !== "#2EAEC1") {
              newDollarValue =
                newParityValue === 0 || newParityValue === ""
                  ? 0
                  : ConvetToNumberType((newLocalValue / newParityValue).toString(), 0, 9999999, 2);
              setFieldValue(`qv_dollarValue_${i}`, newDollarValue);
            }

            newCurrencyValues.push({
              dollarValue: newDollarValue,
              localValue: newLocalValue,
            });
          }
        }
      }}
    />
  );
};

export default InputDollarParity;
