import { Grid } from "@mui/material";
import styled from "@emotion/styled";

export const TotalGrid = styled(Grid)`
  background-color: rgba(217, 217, 217, 0.4);
`;

export const TotalValueTitle = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 52px;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 17px;
  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0px;
    margin-left: 0;
  }
`;

export const TotalValue = styled.div`
  float: left;
  width: 50%;
`;

export const LineQuestionStyles = styled.hr`
  border: 1px solid #d9d9d9;
  background-color: #d9d9d9;
  margin: 0;
`;
