import React, { useState } from "react";
import { useIntl } from "react-intl";
import ButtonContained from "components/ButtonContained";
import HistoryModal from "components/HistoryModal";
import useUser from "hooks/useUser";
import LoginModal from "components/LoginModal";

const History = () => {
  const translate = useIntl();
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { isLogged } = useUser();
  const [openLoginModal, setOpenLoginModal] = useState(false);

  return (
    <>
      {isLogged && <HistoryModal open={open} handleClose={() => setOpen(false)} setDisabledButton={setDisabled} />}
      {!isLogged && <LoginModal open={openLoginModal} handleClose={() => setOpenLoginModal(false)} />}
      <ButtonContained
        disabled={disabled}
        text={translate.formatMessage({ id: "button.historical" })}
        onClick={() => {
          if (isLogged) {
            setDisabled(true);
            setOpen(true);
          } else {
            setOpenLoginModal(true);
          }
        }}
        componentHeight="42px"
      />
    </>
  );
};
export default History;
