import { URLs } from "./settings";

export default function logoutService(userToken) {
  return fetch(`${URLs.apiUrl}/auth/Logout`, {
    method: "DELETE",
    headers: {
      token: userToken,
    },
  })
    .then((res) => {
      if (!res.ok) throw new Error("Response is NOT ok");
    })
    .then((res) => res);
}
