import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FormattedMessage, useIntl } from "react-intl";
import { Parser } from "html-to-react";
import styled from "@emotion/styled";
import { Grid, Paper } from "@mui/material";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import GppBadIcon from "@mui/icons-material/GppBad";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import line7 from "images/line7.svg";

const InfoContainer = styled(Grid)`
  color: #000;
  max-width: 1225px;
  background-color: #d9d9d93d;
  flex-basis: 15%;
  margin-top: 2px;
  margin-bottom: 25px;
  padding-top: 20px;
  padding-left: 21px;
  padding-right: 21px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 19px;
  border-radius: 15px;
`;
const InfoSection1 = styled.div`
  width: 98%;
  flex-direction: row;
  flex-basis: 46%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  margin-bottom: 25px;
  display: flex;
  font-size: 14px;
`;

const Info1ItemSubsectionStyled = styled.span`
  font-weight: 700;
`;

const Info1ItemMailStyled = styled.a`
  color: #2eaec1;
  font-weight: 700;
`;

const Info1Line = styled.img`
  padding-top: 35px;
  height: 280px;
  object-fit: cover;
  align-self: flex-start;
`;
const InfoSection2 = styled(Paper)`
  width: 95%;
  background-color: #ffff;
  flex-direction: row;
  flex-basis: 30%;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 3px;
  margin-bottom: 30px;
  padding-top: 25px;
  padding-bottom: 20px;
  display: flex;
`;
const Info21Styled = styled.div`
  height: 100%;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: flex-start;
  align-items: left;
  padding-bottom: 3px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  max-width: 700px;
`;
const Info211Styled = styled.div`
  font-weight: bolder;
`;
const Info212Styled = styled.div`
  margin-bottom: 2px;
  text-align: justify;
  a {
    color: #2eaec1;
    font-weight: 700;
  }
`;

export default function InfoDesktop() {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const translate = useIntl();
  return (
    <InfoContainer container direction="column" justifyContent="space-evenly" alignItems="center">
      {!matchesMD && (
        <InfoSection1 item>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item style={{ width: "33%", paddingLeft: "30px", paddingRight: "30px", paddingTop: "0" }}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item justifyContent="center">
                  <GppMaybeIcon
                    sx={{
                      width: "58px",
                      height: "69px",
                      color: "rgba(0, 0, 0, 0.3)",
                    }}
                  />
                </Grid>
                <Grid item style={{ textAlign: "justify", paddingTop: "15px" }}>
                  <FormattedMessage id="info.section1" />
                </Grid>
              </Grid>
            </Grid>
            <Info1Line src={line7} />
            <Grid item style={{ width: "33%", paddingLeft: "30px", paddingRight: "30px", paddingTop: "0" }}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item justifyContent="center">
                  <VerifiedUserIcon
                    sx={{
                      width: "54px",
                      height: "64px",
                      color: "rgba(0, 0, 0, 0.3)",
                    }}
                  />
                </Grid>
                <Grid item style={{ textAlign: "justify", paddingTop: "15px" }}>
                  <FormattedMessage id="info.section2" />
                </Grid>
              </Grid>
            </Grid>
            <Info1Line src={line7} />
            <Grid item style={{ width: "33%", paddingLeft: "30px", paddingRight: "30px", paddingTop: "0" }}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item justifyContent="center">
                  <GppBadIcon
                    sx={{
                      width: "58px",
                      height: "69px",
                      color: "rgba(0, 0, 0, 0.3)",
                    }}
                  />
                </Grid>
                <Grid item style={{ textAlign: "justify", paddingTop: "15px" }}>
                  <span>
                    <FormattedMessage id="info.section3_1" />
                  </span>
                  <br />
                  <br />
                  <Info1ItemSubsectionStyled>
                    <FormattedMessage id="info.section3_2" />
                  </Info1ItemSubsectionStyled>
                  <br />
                  <br />
                  <Info1ItemMailStyled href={`mailto:${translate.formatMessage({ id: "info.section3_3" })}`}>
                    <FormattedMessage id="info.section3_3" />
                  </Info1ItemMailStyled>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </InfoSection1>
      )}
      {matchesMD && (
        <>
          <InfoSection1 item>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item style={{ width: "45%", paddingLeft: "30px", paddingRight: "30px", paddingTop: "0" }}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Grid item justifyContent="center">
                    <GppMaybeIcon
                      sx={{
                        width: "58px",
                        height: "69px",
                        color: "rgba(0, 0, 0, 0.3)",
                      }}
                    />
                  </Grid>
                  <Grid item style={{ textAlign: "justify", paddingTop: "15px" }}>
                    <FormattedMessage id="info.section1" />
                  </Grid>
                </Grid>
              </Grid>
              <Info1Line src={line7} />
              <Grid item style={{ width: "45%", paddingLeft: "30px", paddingRight: "30px", paddingTop: "0" }}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Grid item justifyContent="center">
                    <VerifiedUserIcon
                      sx={{
                        width: "54px",
                        height: "64px",
                        color: "rgba(0, 0, 0, 0.3)",
                      }}
                    />
                  </Grid>
                  <Grid item style={{ textAlign: "justify", paddingTop: "15px" }}>
                    <FormattedMessage id="info.section2" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </InfoSection1>
          <InfoSection1 item>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item style={{ width: "48%", paddingLeft: "30px", paddingRight: "30px", paddingTop: "0" }}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Grid item justifyContent="center">
                    <GppBadIcon
                      sx={{
                        width: "58px",
                        height: "69px",
                        color: "rgba(0, 0, 0, 0.3)",
                      }}
                    />
                  </Grid>
                  <Grid item style={{ textAlign: "justify", paddingTop: "15px" }}>
                    <span>
                      <FormattedMessage id="info.section3_1" />
                    </span>
                    <br />
                    <br />
                    <Info1ItemSubsectionStyled>
                      <FormattedMessage id="info.section3_2" />
                    </Info1ItemSubsectionStyled>
                    <br />
                    <br />
                    <Info1ItemMailStyled href={`mailto:${translate.formatMessage({ id: "info.section3_3" })}`}>
                      <FormattedMessage id="info.section3_3" />
                    </Info1ItemMailStyled>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </InfoSection1>
        </>
      )}
      <InfoSection2 elevation={3}>
        <Grid container>
          <Grid item style={{ width: "150px", textAlign: "center" }}>
            <MenuBookIcon
              sx={{
                width: "87px",
                height: "80px",
                color: "rgba(0, 0, 0, 0.3)",
              }}
            />
          </Grid>
          <Grid item>
            <Info21Styled>
              <Info211Styled>
                <FormattedMessage id="info.section4_1" />
              </Info211Styled>
              <Info212Styled>{Parser().parse(translate.formatMessage({ id: "info.section4_2" }))}</Info212Styled>
            </Info21Styled>
          </Grid>
        </Grid>
      </InfoSection2>
    </InfoContainer>
  );
}
