import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  accordionSummaryClasses,
  accordionActionsClasses,
  accordionClasses,
  Grid,
} from "@mui/material";
import styled from "@emotion/styled";

export const AcordionStyled = styled(Accordion)`
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px 4px 20px 0px;
  &:before {
    height: 0px;
  }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)(
  ({ theme }) => `
  background-color: #f0f0f0;
  border-radius: 20px 4px 20px 0;
  min-height: 64px;
  font-size: 24px;
  color: #67676799;
  flex-direction: row-reverse;
  &.${accordionSummaryClasses.expanded} {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px 4px 20px 0px;
  }`
);

export const AccordionDetailsStyled = styled(AccordionDetails)`
  padding-left: 0;
  padding-right: 0;
  background: #f0f0f0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-bo-radius: 0 0 15px 15px;
`;

export const TypographyStyled = styled(Typography)`
  font-weight: bolder;
  text-transform: uppercase;
  font-family: Montserrat;
  padding-left: 25px;
`;

export const Info = styled(Grid)`
  width: 95%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  // display: flex;
  // align-items: center;
  color: #676767;
`;
