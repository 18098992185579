import React, { useState, useEffect } from "react";
import getTransportTypes from "services/getTransportTypes";
import useUser from "hooks/useUser";

const Context = React.createContext();

export function StaticDataProvider({ children }) {
  const { isLogged } = useUser();
  const [transportTypes, setTransportTypes] = useState({});
  const [requestSuccess, setRequestSuccess] = useState(false);

  useEffect(() => {
    getTransportTypes().then((result) => {
      setTransportTypes(result);
      setRequestSuccess(true);
    });
  }, [isLogged]);

  return requestSuccess && <Context.Provider value={{ transportTypes }}>{children}</Context.Provider>;
}

export default Context;
