import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography, Grid } from "@mui/material";
import GlobalContext from "contexts/GlobalContext";
import useLang from "hooks/useLang";
import InputLocalQuestion from "components/InputLocalQuestion";
import InputDollarQuestion from "components/InputDollarQuestion";
import QuestionLink from "components/QuestionLink";
import QuestionHelp from "components/QuestionHelp";

export default function QuestionRow({ question, rowColor, onClick }) {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const minValue = 0;
  const maxValue = 999999999999;
  const { getEstimatorTemplateLanguageText, getTextByLanguage } = useLang();
  const { nextActiveStep } = useContext(GlobalContext);
  const { activeStep } = useContext(GlobalContext);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      style={{
        backgroundColor: rowColor,
        paddingTop: matchesSM ? "10px" : "0",
        paddingBottom: matchesSM ? "10px" : "0",
      }}
    >
      <Grid item xs={12} sm={6} md={8} lg={9}>
        <Typography
          variant="body2"
          style={{
            lineHeight: matchesSM ? "25px" : "52px",
            marginRight: matchesSM ? "10px" : "0",
            fontSize: matchesSM ? "12px" : "14px",
            fontWeight: matchesSM ? "500" : "400",
          }}
        >
          {getEstimatorTemplateLanguageText(question)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} style={{ position: "relative" }}>
        <Grid container>
          <Grid item xs={6}>
            <InputLocalQuestion
              index={question.index}
              minValue={minValue}
              maxValue={maxValue}
              disabled={activeStep > nextActiveStep}
              onClick={onClick}
            />
          </Grid>
          <Grid item xs={6}>
            <InputDollarQuestion
              index={question.index}
              minValue={minValue}
              maxValue={maxValue}
              disabled={activeStep > nextActiveStep}
              onClick={onClick}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          style={{
            position: "absolute",
            top: "-10px",
            right: 0,
          }}
        >
          <Grid item>
            <QuestionLink question={question} />
          </Grid>
          <Grid item>
            <QuestionHelp question={question} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
