import { URLs } from "./settings";

export default function getTransportTypes(jwt) {
  const apiURL = `${URLs.apiUrl}/TransportTypes`;
  return (
    fetch(apiURL)
      // ,{
      //   headers: {
      //     Authorization: `Bearer ${jwt}`,
      //   },
      // })
      .then((res) => {
        if (!res.ok) throw new Error("Response is NOT ok");
        return res.json();
      })
      .then((res) => res)
  );
}
