import * as React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TextField, Autocomplete, Box } from "@mui/material";
import { useFormikContext } from "formik";
import useLang from "hooks/useLang";

export default function CountrySelect({ labelTitle, componentName, fieldNames, fieldNameValue, variant, fullWidth }) {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const { getCountries } = useLang();
  const { values, setFieldValue } = useFormikContext();
  const widthParam = fullWidth ? "100%" : "250px";
  return (
    <Autocomplete
      sx={{ width: matchesSM || matchesMD ? "100%" : widthParam, marginTop: matchesSM || matchesMD ? "20px" : "10px" }}
      value={values[fieldNameValue]}
      onChange={(event, newInputValue) => {
        fieldNames.forEach((fieldName) => {
          setFieldValue(fieldName, newInputValue);
        });
      }}
      options={getCountries() ?? []}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          // name={componentName}
          // as={TextField}
          label={labelTitle}
          variant={variant}
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
