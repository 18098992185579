import * as React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

const ButtonStyled = styled(Button)`
  @media (max-width: 600px) {
    margin-top: 10px;
  }
  color: #fff;
  font-family: Roboto;
  border-radius: 20px;
  width: ${(props) => props.componentwidth};
  height: ${(props) => props.componentHeight};
`;
const ButtonSpanStyled = styled.span`
  font-weight: bolder;
  text-transform: uppercase;
`;
function ButtonContained({ componentwidth, componentHeight, onClick, type, text, disabled }) {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ButtonStyled
      disabled={disabled}
      variant="contained"
      size="large"
      componentHeight={componentHeight}
      componentwidth={matchesSM ? "100%" : componentwidth}
      onClick={onClick}
      type={type}
    >
      <ButtonSpanStyled>{text}</ButtonSpanStyled>
    </ButtonStyled>
  );
}
export default ButtonContained;
