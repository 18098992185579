import styled from "@emotion/styled";

export const SubtitleStyled = styled.span`
  font-size: 14px;
  color: #000;
  font-family: roboto;
`;

export const HeaderStyled = styled.div`
  width: 50%;
  font-size: 14px;
  color: #000;
  font-family: roboto;
  float: left;
  @media (max-width: 900px) {
    width: 50%;
    text-align: center;
  }
`;
