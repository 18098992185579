import React from "react";
import { useIntl } from "react-intl";
import { Grid, Avatar } from "@mui/material";
import useUser from "hooks/useUser";
import {
  BoxStyled,
  GridAvatarStyled,
  TypographyHeaderStyled,
  TypographyContentStyled,
  ChipDeleteStyled,
  ChipEditStyled,
  ChipDuplicateStyled,
} from "components/HistoryRow/style";
import useLang from "hooks/useLang";

const HistoryRow = ({ estimation, onClickDeleteHandler, onClickEditHandler, onClickDuplicateHandler }) => {
  const translate = useIntl();
  const { user } = useUser();
  const countries = useLang().getCountries();

  return (
    <BoxStyled key={estimation.id}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <GridAvatarStyled item xs={2}>
          <Avatar sx={{ bgcolor: "#D9D9D9" }}>
            {user.firstName.split(" ")[0].substring(0, 1).toUpperCase() +
              user.lastName.split(" ")[0].substring(0, 1).toUpperCase()}
          </Avatar>
        </GridAvatarStyled>
        <Grid item xs={10} md={7}>
          <TypographyHeaderStyled>{estimation.name}</TypographyHeaderStyled>
          <TypographyContentStyled>
            <span style={{ fontWeight: "500" }}>{translate.formatMessage({ id: "label.lastUpdate" })}</span>&nbsp;
            {estimation.modifiedDate == null
              ? estimation.createdDate.toString().replace("T", " ").substring(0, 16)
              : estimation.modifiedDate.toString().replace("T", " ").substring(0, 16)}
          </TypographyContentStyled>
          <TypographyContentStyled>
            <span style={{ fontWeight: "500" }}>{translate.formatMessage({ id: "label.origin" })}</span> &nbsp;
            {estimation.originCity} &nbsp;
            {estimation.originCountry !== ""
              ? countries.find((element) => element.code === estimation.originCountry).label
              : ""}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontWeight: "500" }}>{translate.formatMessage({ id: "label.destination" })}</span> &nbsp;
            {estimation.destinationCity}&nbsp;
            {estimation.destinationCountry !== ""
              ? countries.find((element) => element.code === estimation.destinationCountry).label
              : ""}
          </TypographyContentStyled>
          <TypographyContentStyled>
            <span style={{ fontWeight: "500" }}>{translate.formatMessage({ id: "input.article" })}</span> &nbsp;
            {estimation.product}&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontWeight: "500" }}>{translate.formatMessage({ id: "input.transportType" })}</span>&nbsp;
            {estimation.transportTypeCode !== "" ? translate.formatMessage({ id: estimation.transportTypeCode }) : "-"}
          </TypographyContentStyled>
        </Grid>
        <Grid item xs={12} md={4}>
          <ChipDeleteStyled
            label={translate.formatMessage({ id: "button.delete" })}
            size="small"
            variant="outlined"
            onClick={onClickDeleteHandler}
            clickable
          />
          <ChipEditStyled
            label={translate.formatMessage({ id: "button.edit" })}
            size="small"
            onClick={onClickEditHandler}
            clickable
          />
          <ChipDuplicateStyled
            label={translate.formatMessage({ id: "button.duplicate" })}
            size="small"
            onClick={onClickDuplicateHandler}
            clickable
          />
        </Grid>
      </Grid>
    </BoxStyled>
  );
};

export default HistoryRow;
