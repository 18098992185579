import React, { useState } from "react";

const Context = React.createContext({});

export function GlobalProvider({ children }) {
  const [updateMode, setUpdateMode] = useState(false);
  const [toUpdateMode, setToUpdateMode] = useState(false);
  const [estimation, setEstimation] = useState(null);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [resetToNew, setResetToNew] = useState(false);
  const [totalByGroups, setTotalByGroups] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [completedGroups, setCompletedGroups] = useState([]);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [nextActiveStep, setNextActiveStep] = useState(1);
  const [exportToPdf, setExportToPdf] = useState(false);
  const [readyToExportPdf, setReadyToExportPdf] = useState(false);

  return (
    <Context.Provider
      value={{
        updateMode,
        setUpdateMode,
        estimation,
        setEstimation,
        isDuplicate,
        setIsDuplicate,
        resetToNew,
        setResetToNew,
        totalByGroups,
        setTotalByGroups,
        activeStep,
        setActiveStep,
        completedGroups,
        setCompletedGroups,
        nextActiveStep,
        setNextActiveStep,
        completedSteps,
        setCompletedSteps,
        toUpdateMode,
        setToUpdateMode,
        exportToPdf,
        setExportToPdf,
        readyToExportPdf,
        setReadyToExportPdf,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default Context;
