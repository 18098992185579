import { useCallback, useContext, useState } from "react";
import ReactGA from "react-ga4";
import Context from "contexts/UserContext";
import logoutService from "services/logout";

export default function useUser() {
  const { user: user1, setUser } = useContext(Context);
  const [state, setState] = useState({ loading: false, error: false });

  const login = useCallback(
    (userData) => {
      setState({ loading: true, error: false });
      // window.sessionStorage.setItem("user", typeof user1 === "string" ? userData : JSON.stringify(userData));
      window.sessionStorage.setItem("user", userData);
      setState({ loading: false, error: false });
      setUser(userData);
      ReactGA.set({ userId: JSON.parse(userData).email });
    },
    [setUser]
  );

  const logout = useCallback(
    (logoutSvc) => {
      const user = user1 ? JSON.parse(user1) : null;
      logoutService(user.tokenCA ? user.tokenCA : "").then((res) => {
        window.sessionStorage.removeItem("user");
        setUser(null);
        ReactGA.set({ userId: "Visitante" });
        window.location.reload(false);
      });
    },
    [setUser]
  );

  return {
    isLogged: Boolean(user1),
    user: user1 ? JSON.parse(user1) : null,
    isLoginLoading: state.loading,
    hasLoginError: state.error,
    login,
    logout,
  };
}
