export const URLs = {
  apiUrl: process.env.REACT_APP_API_URL,
  jsUrl: process.env.REACT_APP_JS_URL,
  imgUrl: process.env.REACT_APP_IMAGE_URL,
  connectAmericasApiUrl: process.env.REACT_APP_CONNECT_AMERICAS_API_URL,
  gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
  casUrlAuth: process.env.REACT_APP_CAS_URL_AUTH,
  serviceUrlAuth: process.env.REACT_APP_SERVICE_URL_AUTH,
  registerUserUrl: process.env.REACT_APP_REGISTER_USER_URL,
};
