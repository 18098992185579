import React, { useEffect, useContext } from "react";
import { Field, useFormikContext } from "formik";
import ConvetToNumberType from "utils";
import { GetTotalsSubsection, GetTotalsRelated, SetValuesQuestionRow } from "components/InputDollarQuestion/helper";
import GlobalContext from "contexts/GlobalContext";
import { TexFieldEstimatorStyled } from "components/QuestionRow/styles";
import DialogContext from "contexts/DialogContext";

const InputLocalQuestion = ({ index, minValue, maxValue, disabled, onClick }) => {
  const { completedGroups, setCompletedGroups, nextActiveStep, setNextActiveStep, activeStep } =
    useContext(GlobalContext);
  const { values, setFieldValue } = useFormikContext();

  // Se dispara al modificar un valor de cotizacion
  useEffect(() => {
    let hasValidValue = false;
    const groupId = values[`qv_groupId_${index}`];

    if (values[`qv_localValue_${index}`] > 0) {
      hasValidValue = true;
      if (!completedGroups.includes(groupId)) {
        const newArray = [...completedGroups, groupId];
        setCompletedGroups(newArray);
        setNextActiveStep(newArray.length + 1);
      }
    } else {
      hasValidValue = Object.entries(values)
        .filter((x) => x[0].includes("qv_groupId_") && x[1] === groupId)
        .map((x) => x[0].split("_")[2])
        .some(
          (x) => !values[`qv_isTotal_${x}`] && (values[`qv_localValue_${x}`] > 0 || values[`qv_dollarValue_${x}`] > 0)
        );
    }

    if (!hasValidValue) {
      const newArray = completedGroups.filter((gId) => gId !== groupId);
      setCompletedGroups(newArray);
      if (nextActiveStep > 1 && activeStep < nextActiveStep) {
        setNextActiveStep(nextActiveStep - 1);
      }
    }
  }, [values[`qv_localValue_${index}`]]);

  return (
    <Field
      as={TexFieldEstimatorStyled}
      variant="standard"
      name={`qv_localValue_${index}`}
      type="number"
      disabled={disabled}
      inputProps={{
        min: minValue,
        max: maxValue,
      }}
      fontColor={values[`qv_colorChangeLocalValue_${index}`]}
      onClick={(option) => {
        onClick({ isDisabled: disabled });
      }}
      onFocus={(option) => {
        if (option.target.value === "0") option.target.select();
      }}
      onBlur={(option) => {
        let currentIndex = Number(option.target.name.split("_").slice(-1)[0]);
        if (values[`qv_localValue_${currentIndex}`] === "") {
          setFieldValue(`qv_dollarValue_${currentIndex}`, 0);
          setFieldValue(`qv_localValue_${currentIndex}`, 0);
        }
      }}
      onChange={(option) => {
        let currentIndex = Number(option.target.name.split("_").slice(-1)[0]);

        const newLocalValue =
          option.target.value !== "" ? ConvetToNumberType(option.target.value, minValue, maxValue, 2) : "";
        const newDollarValue =
          values.dollarParity === 0
            ? 0
            : ConvetToNumberType((newLocalValue / values.dollarParity).toString(), minValue, maxValue, 2);

        SetValuesQuestionRow(setFieldValue, currentIndex, newDollarValue, newLocalValue, false);

        let { indexTotalSubsection, totalDollarValue, totalLocalValue } = GetTotalsSubsection(
          values,
          currentIndex,
          newDollarValue,
          newLocalValue === "" ? 0 : newLocalValue
        );

        setFieldValue(`qv_dollarValue_${indexTotalSubsection}`, totalDollarValue);
        setFieldValue(`qv_localValue_${indexTotalSubsection}`, totalLocalValue);

        let hasIndexRelated = values[`qv_subsectionIndexRelated_${currentIndex}`].length > 0;

        const totalValuesRelated = [
          {
            index: indexTotalSubsection,
            dollarValue: totalDollarValue,
            localValue: totalLocalValue,
          },
        ];
        while (hasIndexRelated) {
          const { subsectionIndexRelated, totalDollarValueRelated, totalLocalValueRelated } = GetTotalsRelated(
            values,
            currentIndex,
            totalDollarValue,
            totalLocalValue,
            totalValuesRelated,
            setFieldValue
          );
          setFieldValue(`qv_dollarValue_${subsectionIndexRelated}`, totalDollarValueRelated);
          setFieldValue(`qv_localValue_${subsectionIndexRelated}`, totalLocalValueRelated);

          totalValuesRelated.push({
            index: subsectionIndexRelated,
            dollarValue: totalDollarValueRelated,
            localValue: totalLocalValueRelated,
          });

          hasIndexRelated = values[`qv_subsectionIndexRelated_${subsectionIndexRelated}`].length > 0;
          currentIndex = subsectionIndexRelated;
          totalDollarValue = totalDollarValueRelated;
          totalLocalValue = totalLocalValueRelated;
        }

        // for (let i = indexTotalSubsection + 1; i < values.totalQuestions; i++) {
        //   if (values[`qv_isPercentage_${i}`] === true) {
        //     const ssoIndexTotalSubsection = values[`qv_subsectionOperation_indexTotalSubsection_${i}`];
        //     let totalValueRelated = totalValuesRelated.find((x) => x.index === ssoIndexTotalSubsection);
        //     const dollarValue = (values[`qv_percentageValue_${i}`] * totalValueRelated.dollarValue) / 100;
        //     const localValue = (values[`qv_percentageValue_${i}`] * totalValueRelated.localValue) / 100;

        //     SetValuesQuestionPercentRow(setFieldValue, i, values[`qv_percentageValue_${i}`], dollarValue, localValue);

        //     const indexFirstQuestionSubsectionPercent = values[`qv_indexFirstQuestionSubsection_${i}`];
        //     const indexTotalSubsectionPercent = values[`qv_indexTotalSubsection_${i}`];
        //     let totalDollarValuePercent = 0;
        //     let totalLocalValuePercent = 0;
        //     for (let x = indexFirstQuestionSubsectionPercent; x < indexTotalSubsectionPercent; x++) {
        //       totalDollarValuePercent += x === i ? newDollarValue : Number(values[`qv_dollarValue_${x}`]);
        //       totalLocalValuePercent += x === i ? newLocalValue : Number(values[`qv_localValue_${x}`]);
        //     }

        //     if (values[`qv_subsectionOperation_indexTotalSubsection_${indexTotalSubsectionPercent}`] != null) {
        //       const ssoIndexTotalSubsectionPercent =
        //         values[`qv_subsectionOperation_indexTotalSubsection_${indexTotalSubsectionPercent}`];
        //       if (values[`qv_subsectionOperation_operationType_${indexTotalSubsectionPercent}`] === 0) {
        //         totalDollarValuePercent += Number(values[`qv_dollarValue_${ssoIndexTotalSubsectionPercent}`]);
        //         totalLocalValuePercent += Number(values[`qv_localValue_${ssoIndexTotalSubsectionPercent}`]);
        //       } else {
        //         totalDollarValuePercent =
        //           Number(values[`qv_dollarValue_${ssoIndexTotalSubsectionPercent}`]) - totalDollarValuePercent;
        //         totalLocalValuePercent =
        //           Number(values[`qv_localValue_${ssoIndexTotalSubsectionPercent}`]) - totalLocalValuePercent;
        //       }
        //     }

        //     let totalsSubsection = {
        //       indexTotalSubsectionPercent,
        //       totalDollarValuePercent,
        //       totalLocalValuePercent,
        //     }; // GetTotalsSubsection(values, i, dollarValue, localValue);

        //     setFieldValue(
        //       `qv_dollarValue_${totalsSubsection.indexTotalSubsectionPercent}`,
        //       totalsSubsection.totalDollarValuePercent
        //     );
        //     setFieldValue(
        //       `qv_localValue_${totalsSubsection.indexTotalSubsectionPercent}`,
        //       totalsSubsection.totalLocalValuePercent
        //     );
        //   }
        // }
      }}
    />
  );
};

export default InputLocalQuestion;
