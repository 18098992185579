import { useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import useLang from "hooks/useLang";
import Context from "contexts/StaticDataContext";

export default function useStaticData() {
  const translate = useIntl();
  const { locale } = useLang();
  const { transportTypes } = useContext(Context);

  const getTransportTypes = useCallback((value) => {
    let result = transportTypes.map((tt) => ({
      code: tt.code,
      description: translate.formatMessage({ id: tt.code }),
    }));

    switch (locale) {
      case "es":
        result.unshift({ code: "0", description: "Sin especificar" });
        break;
      case "pt":
        result.unshift({ code: "0", description: "Indeterminado" });
        break;
      default:
        result.unshift({ code: "0", description: "Unspecified" });
    }

    return result;
  });

  return {
    getTransportTypes,
  };
}
