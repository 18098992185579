import React from "react";
import { Link, Tooltip } from "@mui/material";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { Parser } from "html-to-react";
import useLang from "hooks/useLang";

export default function QuestionLink({ question, pRight = 0 }) {
  const { getTextByLanguage } = useLang();
  return (
    question.hasLink && (
      <Tooltip
        title={Parser().parse(
          getTextByLanguage(
            question.spanishLinkDescription,
            question.portugueseLinkDescription,
            question.englishLinkDescription
          )
        )}
        placement="left"
        leaveTouchDelay={3000}
        enterTouchDelay={50}
      >
        <Link href={question.linkUrl} target="_blank">
          <AddLinkIcon
            sx={{
              width: "30px",
              height: "23px",
              color: "rgba(0, 0, 0, 0.54)",
            }}
          />
        </Link>
      </Tooltip>
    )
  );
}
