import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Dialog, Grid, CircularProgress } from "@mui/material";
import DowloadIcon from "@mui/icons-material/Download";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { fontWeight } from "@mui/system";
import { DialogContentStyled } from "components/ExportPdfModal/style";
import BootstrapDialogTitle from "components/BootstrapDialogTitle";
import PdfEstimator from "components/PdfEstimator";


const ExportPdfModal = ({
  open,
  handleClose,
  estimatorTemplate,
  estimation,
  translate,
  getEstimatorTemplateLanguageText,
  locale,
}) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  let dowloadLabel = "";
  let loadingLabel = "";

  switch (locale) {
    case "pt":
      dowloadLabel = "Descarga estimativa";
      loadingLabel = "Carregando documento";
      break;
    case "es":
      dowloadLabel = "Descargar estimación";
      loadingLabel = "Cargando documento";
      break;
    default:
      dowloadLabel = "Download estimation";
      loadingLabel = "Loading document";
      break;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="xl"
      style={{ marginTop: "40px" }}
      disablePortal
    >
      {!matchesSM && <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} />}
      <DialogContentStyled>
        {!matchesSM && (
          <PDFViewer width="1200" height="600px">
            <PdfEstimator
              estimatorTemplate={estimatorTemplate}
              translate={translate}
              getEstimatorTemplateLanguageText={getEstimatorTemplateLanguageText}
              estimation={estimation}
              locale={locale}
            />
          </PDFViewer>
        )}
        {matchesSM && (
          <PDFDownloadLink
            document={
              <PdfEstimator
                estimatorTemplate={estimatorTemplate}
                translate={translate}
                getEstimatorTemplateLanguageText={getEstimatorTemplateLanguageText}
                estimation={estimation}
                locale={locale}
              />
            }
            fileName="estimation.pdf"
          >
            {({ loading }) =>
              loading ? (
                <Grid container direction="row" justifyContent="center" alignItems="stretch">
                  <Grid item>
                    <span style={{ color: "rgba(0, 0, 0, 0.6)", fontWeight: "bolder" }}>{loadingLabel} &nbsp;</span>
                  </Grid>
                  <Grid item>
                    <CircularProgress color="inherit" size={20} />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  style={{ color: "rgb(117, 170, 212)", fontWeight: "bolder" }}
                >
                  <Grid item>{dowloadLabel} &nbsp;</Grid>
                  <Grid item>
                    <DowloadIcon />
                  </Grid>
                </Grid>
              )
            }
          </PDFDownloadLink>
        )}
      </DialogContentStyled>
    </Dialog>
  );
};

export default ExportPdfModal;
