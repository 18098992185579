import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Grid, Snackbar, Alert, AlertTitle } from "@mui/material";
import useLang from "hooks/useLang";
import SectionAccordion from "components/SectionAccordion";
import QuestionRow from "components/QuestionRow";
import QuestionPercentRow from "components/QuestionPercentRow";
import TotalRow from "components/TotalRow";
import { HeaderStyled, SubtitleStyled } from "components/Section/style";

const Section = ({ section }) => {
  const translate = useIntl();
  const { getEstimatorTemplateLanguageText } = useLang();
  const [openSnackWarning, setOpenSnackWarning] = useState(false);
  const openDialog = ({ isDisabled }) => {
    if (isDisabled) {
      setOpenSnackWarning(true);
    }
  };

  return (
    <>
      <Snackbar open={openSnackWarning} autoHideDuration={4000} onClose={() => setOpenSnackWarning(false)}>
        <Alert
          elevation={6}
          severity="warning"
          variant="filled"
          onClose={() => setOpenSnackWarning(false)}
          sx={{ width: "100%" }}
        >
          <AlertTitle>{translate.formatMessage({ id: "message.validationAccessGroup" })}</AlertTitle>
        </Alert>
      </Snackbar>
      <SectionAccordion section={section}>
        {(subsections) =>
          subsections.map((subsection) => (
            <>
              {(!subsection.hideTextTitle || !subsection.hideCurrencyTitle) && (
                <>
                  <br />
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ backgroundColor: "#E0ECF6", height: "52px", fontWeight: "bold" }}
                  >
                    <Grid item xs={12} sm={6} md={8} lg={9}>
                      {!subsection.hideTextTitle && (
                        <SubtitleStyled>{getEstimatorTemplateLanguageText(subsection)}</SubtitleStyled>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      {!subsection.hideCurrencyTitle && (
                        <>
                          <HeaderStyled>{translate.formatMessage({ id: "title.localCurrency" })}</HeaderStyled>
                          <HeaderStyled>USD</HeaderStyled>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
              {subsection.questions.map((question, index) => {
                if (question.isPercentage) {
                  return <QuestionPercentRow question={question} rowColor={index % 2 ? "#E0ECF6" : "#FFF"} />;
                }
                if (question.isTotal) {
                  return <TotalRow question={question} />;
                }
                return (
                  <QuestionRow question={question} rowColor={index % 2 ? "#E0ECF6" : "#FFF"} onClick={openDialog} />
                );
              })}
            </>
          ))
        }
      </SectionAccordion>
    </>
  );
};

export default Section;
