import React from "react";
import { useIntl } from "react-intl";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import SnackbarSuccess from "components/SnackbarSuccess";

const ConfirmationDuplicate = ({ open, setOpen, onClickHandler }) => {
  const translate = useIntl();
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent id="alert-dialog-title">{translate.formatMessage({ id: "message.duplicate" })}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            autoFocus
          >
            {translate.formatMessage({ id: "button.cancel" })}
          </Button>
          <Button onClick={onClickHandler}>{translate.formatMessage({ id: "button.accept" })}</Button>
        </DialogActions>
      </Dialog>

      <SnackbarSuccess messageSuccess={translate.formatMessage({ id: "message.duplicationSuccess" })} />
    </>
  );
};

export default ConfirmationDuplicate;
