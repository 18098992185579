import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import ConvetToNumberType from "utils";
import InputPercent from "components/InputPercent";
import GlobalContext from "contexts/GlobalContext";
import useLang from "hooks/useLang";
import QuestionLink from "components/QuestionLink";
import QuestionHelp from "components/QuestionHelp";
import { ValueGrid, TotalValue, PercentValueGrid } from "./styles";

export default function QuestionPercentRow({ question, rowColor }) {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { getEstimatorTemplateLanguageText } = useLang();
  const { nextActiveStep, activeStep } = useContext(GlobalContext);
  const { values } = useFormikContext();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      style={{ backgroundColor: rowColor }}
    >
      <Grid item xs={8} sm={4} md={6} lg={7}>
        <Typography
          variant="body2"
          style={{
            lineHeight: matchesSM ? "25px" : "52px",
            marginRight: matchesSM ? "10px" : "0",
            fontSize: matchesSM ? "12px" : "14px",
            fontWeight: matchesSM ? "500" : "400",
          }}
        >
          {getEstimatorTemplateLanguageText(question)}
        </Typography>
      </Grid>
      <PercentValueGrid xs={4} item sm={2} md={2}>
        <InputPercent index={question.index} disabled={activeStep > nextActiveStep} />
      </PercentValueGrid>
      <ValueGrid item xs={12} sm={6} md={4} lg={3} style={{ position: "relative" }}>
        <TotalValue>
          <Typography variant="body2" style={{ fontSize: "16px" }}>
            {ConvetToNumberType(values[`qv_localValue_${question.index}`].toString(), 0, 999999999999, 2)}
          </Typography>
        </TotalValue>
        <TotalValue>
          <Typography variant="body2" style={{ fontSize: "16px" }}>
            {ConvetToNumberType(values[`qv_dollarValue_${question.index}`].toString(), 0, 999999999999, 2)}
          </Typography>
        </TotalValue>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          style={{
            position: "absolute",
            top: "-10px",
            right: 0,
          }}
        >
          <Grid item>
            <QuestionLink question={question} />
          </Grid>
          <Grid item>
            <QuestionHelp question={question} />
          </Grid>
        </Grid>
      </ValueGrid>
    </Grid>
  );
}
