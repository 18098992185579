import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import useUser from "hooks/useUser";
import useLang from "hooks/useLang";
import ConvetToNumberType from "utils";
import QuestionLink from "components/QuestionLink";
import QuestionHelp from "components/QuestionHelp";
import { TotalGrid, TotalValueTitle, TotalValue } from "components/TotalRow/styles";

export default function TotalRow({ question }) {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { getEstimatorTemplateLanguageText } = useLang();
  const { values } = useFormikContext();
  const { isLogged } = useUser();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      style={{ backgroundColor: matchesSM ? "rgba(217, 217, 217, 0.8)" : "transparent" }}
    >
      <Grid item sm={0} md={3} lg={5} />
      <TotalGrid item xs={12} sm={6} md={5} lg={4}>
        <TotalValueTitle>{getEstimatorTemplateLanguageText(question)}</TotalValueTitle>
      </TotalGrid>
      <TotalGrid item xs={12} sm={6} md={3} style={{ position: "relative" }}>
        <TotalValue>
          <Typography variant="body2">
            {isLogged
              ? ConvetToNumberType(values[`qv_localValue_${question.index}`].toString(), 0, 999999999999, 2)
              : 0}
          </Typography>
        </TotalValue>
        <TotalValue>
          <Typography variant="body2">
            {isLogged
              ? ConvetToNumberType(values[`qv_dollarValue_${question.index}`].toString(), 0, 999999999999, 2)
              : 0}
          </Typography>
        </TotalValue>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          style={{
            position: "absolute",
            top: "-10px",
            right: 0,
          }}
        >
          <Grid item>
            <QuestionLink question={question} />
          </Grid>
          <Grid item>
            <QuestionHelp question={question} />
          </Grid>
        </Grid>
      </TotalGrid>
    </Grid>
  );
}
