import { Box, Grid, Typography, Chip } from "@mui/material";
import styled from "@emotion/styled";

export const BoxStyled = styled(Box)`
  @media (max-width: 600px) {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  // width: 1090px;
  background: rgba(217, 217, 217, 0.18);
  border: 1px solid rgba(0, 0, 0, 0.09);
  margin-top: 18px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 0;
  padding-right: 0;
`;

export const GridAvatarStyled = styled(Grid)`
  @media (max-width: 600px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  padding-left: 10px;
  padding-right: 12px;
`;

export const TypographyHeaderStyled = styled(Typography)`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;

export const TypographyContentStyled = styled(Typography)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: bolder;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.6);
`;

export const ChipDeleteStyled = styled(Chip)`
  background: #ffff;
  border: 1px solid rgba(3, 35, 80, 1);
  font-family: "Roboto";
  font-style: normal;
  font-weight: bolder;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: #032350;
  margin-right: 12px;
  text-transform: uppercase;
`;

export const ChipEditStyled = styled(Chip)`
  background: #2eaec1;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bolder;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 12px;
  text-transform: uppercase;
`;

export const ChipDuplicateStyled = styled(Chip)`
  background: #032350;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bolder;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: rgba(255, 255, 255, 0.87);
  text-transform: uppercase;
`;
