import React, { useState, useContext } from "react";
import { Skeleton } from "@mui/material";
import HistoryRow from "components/HistoryRow";
import NonInfoMessage from "components/NonInfoMessage";
import deleteEstimation from "services/deleteEstimation";
import getEstimation from "services/getEstimation";
import ConfirmationDelete from "components/ConfirmationDelete";
import ConfirmationDuplicate from "components/ConfirmationDuplicate";
import useUser from "hooks/useUser";
import GlobalContext from "contexts/GlobalContext";

const HistoryList = ({
  estimations,
  isValueLoded,
  estimationDeletedSuccessHandler,
  onClickRowEditHandler,
  estimationDuplictedSuccessHandler,
}) => {
  const { updateMode, setUpdateMode, estimation, setEstimation, isDuplicate, setIsDuplicate, setResetToNew } =
    useContext(GlobalContext);
  const { user } = useUser();
  const [idSelected, setIdSelected] = useState(null);
  const [nameSelected, setNameSelected] = useState("");
  const [openConfirmationDelete, setOpenConfirmationDelete] = useState(false);
  const [openConfirmationDuplicate, setOpenConfirmationDuplicate] = useState(false);

  if (isValueLoded && estimations.length === 0) {
    return <NonInfoMessage width="100%" />;
  }

  if (!isValueLoded) {
    return (
      <>
        <Skeleton variant="rectangular" width="100%" height={82} />
        <br />
        <Skeleton variant="rectangular" width="100%" height={82} />
      </>
    );
  }

  return (
    <>
      {estimations.map((est) => (
        <HistoryRow
          estimation={est}
          onClickDeleteHandler={() => {
            setOpenConfirmationDelete(true);
            setIdSelected(est.id);
            setNameSelected(est.name);
          }}
          onClickEditHandler={() => {
            onClickRowEditHandler(est.id);
          }}
          onClickDuplicateHandler={() => {
            setOpenConfirmationDuplicate(true);
            setIdSelected(est.id);
          }}
        />
      ))}
      <ConfirmationDelete
        open={openConfirmationDelete}
        setOpen={setOpenConfirmationDelete}
        entityName={nameSelected}
        onClickHandler={() => {
          deleteEstimation(user.token, idSelected).then(() => {
            if (updateMode) setUpdateMode(false);
            if (estimation !== null) setEstimation(null);
            if (isDuplicate) setIsDuplicate(false);
            setResetToNew(true);
            estimationDeletedSuccessHandler();
            setOpenConfirmationDelete(false);
          });
        }}
      />
      <ConfirmationDuplicate
        open={openConfirmationDuplicate}
        setOpen={setOpenConfirmationDuplicate}
        onClickHandler={() => {
          getEstimation(user.token, idSelected).then((est) => {
            const newEst = est;
            newEst.id = 0;
            newEst.name = "";
            const newEstimateDetails = est.estimateDetails.map((ed) => {
              const newEd = ed;
              newEd.estimateId = 0;
              return newEd;
            });
            newEst.estimateDetails = newEstimateDetails;
            setEstimation(newEst);
            setUpdateMode(false);
            setIsDuplicate(true);
            setOpenConfirmationDuplicate(false);
            estimationDuplictedSuccessHandler();
          });
        }}
      />
    </>
  );
};

export default HistoryList;
