import { URLs } from "./settings";

export default function addEstimation(jwt, estimation) {
  const apiURL = `${URLs.apiUrl}/Estimator/EstimationsValues`;
  return fetch(apiURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(estimation),
  })
    .then((res) => {
      if (!res.ok) throw new Error("Response is NOT ok");
      return res.json();
    })
    .then((res) => {
      const { id } = res;
      return id;
    });
}
