import { URLs } from "./settings";

const fromApiResponseToEstimations = (apiResponse) => {
  if (Array.isArray(apiResponse)) {
    return apiResponse;
  }
  return [];
};

export default function getEstimations(jwt) {
  const apiURL = `${URLs.apiUrl}/Estimator/EstimationsValues`;
  return fetch(apiURL, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
    .then((res) => {
      if (!res.ok) throw new Error("Response is NOT ok");
      return res.json();
    })
    .then(fromApiResponseToEstimations);
}
