import React from "react";
import { InputAdornment } from "@mui/material";
import { Field, useFormikContext } from "formik";
import ConvetToNumberType from "utils";
import { GetCurrencyValuesFromPercent, SetValuesQuestionPercentRow } from "components/InputPercent/helper";
import { GetTotalsSubsection, GetTotalsRelated } from "components/InputDollarQuestion/helper";
import { TexFieldEstimatorStyled } from "components/QuestionPercentRow/styles";

const InputPercent = ({ index, disabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const minValue = 0;

  const maxValue = values[`qv_maxPercentage_${index}`] === null ? 9999999 : values[`qv_maxPercentage_${index}`];
  return (
    <Field
      as={TexFieldEstimatorStyled}
      variant="standard"
      name={`qv_percentageValue_${index}`}
      type="number"
      disabled={disabled}
      InputProps={{
        endAdornment: <InputAdornment position="end">% </InputAdornment>,
      }}
      inputProps={{
        min: minValue,
        max: maxValue,
      }}
      onFocus={(option) => {
        if (option.target.value === "0") option.target.select();
      }}
      onChange={(option) => {
        let currentIndex = Number(option.target.name.split("_").slice(-1)[0]);

        const percentageValue =
          option.target.value !== "" ? ConvetToNumberType(option.target.value, minValue, maxValue, 2) : "";

        const { dollarValue, localValue } = GetCurrencyValuesFromPercent(values, currentIndex, percentageValue);

        SetValuesQuestionPercentRow(setFieldValue, currentIndex, percentageValue, dollarValue, localValue);

        let { indexTotalSubsection, totalDollarValue, totalLocalValue } = GetTotalsSubsection(
          values,
          currentIndex,
          dollarValue,
          localValue
        );
        setFieldValue(`qv_dollarValue_${indexTotalSubsection}`, totalDollarValue);
        setFieldValue(`qv_localValue_${indexTotalSubsection}`, totalLocalValue);

        let hasIndexRelated = values[`qv_subsectionIndexRelated_${currentIndex}`].length > 0;

        const totalValuesRelated = [
          {
            index: indexTotalSubsection,
            dollarValue: totalDollarValue,
            localValue: totalLocalValue,
          },
        ];
        while (hasIndexRelated) {
          const { subsectionIndexRelated, totalDollarValueRelated, totalLocalValueRelated } = GetTotalsRelated(
            values,
            currentIndex,
            totalDollarValue,
            totalLocalValue,
            totalValuesRelated,
            setFieldValue
          );
          setFieldValue(`qv_dollarValue_${subsectionIndexRelated}`, totalDollarValueRelated);
          setFieldValue(`qv_localValue_${subsectionIndexRelated}`, totalLocalValueRelated);

          totalValuesRelated.push({
            index: subsectionIndexRelated,
            dollarValue: totalDollarValueRelated,
            localValue: totalLocalValueRelated,
          });

          hasIndexRelated = values[`qv_subsectionIndexRelated_${subsectionIndexRelated}`].length > 0;
          currentIndex = subsectionIndexRelated;
          totalDollarValue = totalDollarValueRelated;
          totalLocalValue = totalLocalValueRelated;
        }
      }}
    />
  );
};

export default InputPercent;
