import { URLs } from "./settings";

export default function addEstimation(jwt, id) {
  const apiURL = `${URLs.apiUrl}/Estimator`;
  const formData = new FormData();
  formData.append("id", id);
  return fetch(apiURL, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    body: formData,
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
  });
}
