import React, { useState, useContext } from "react";
import { useIntl } from "react-intl";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useFormikContext } from "formik";
import useAnalyticsEventTracker from "utils/useAnalyticsEventTracker";
import deleteEstimation from "services/deleteEstimation";
import getEstimation from "services/getEstimation";
import ConfirmationDelete from "components/ConfirmationDelete";
import ConfirmationDuplicate from "components/ConfirmationDuplicate";
import useUser from "hooks/useUser";
import GlobalContext from "contexts/GlobalContext";

const DotsMenu = () => {
  const gaEventTracker = useAnalyticsEventTracker("user_action");
  const translate = useIntl();
  const {
    updateMode,
    setUpdateMode,
    estimation,
    setEstimation,
    isDuplicate,
    setIsDuplicate,
    setResetToNew,
    resetToNew,
  } = useContext(GlobalContext);
  const { user } = useUser();
  const { values } = useFormikContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmationDelete, setOpenConfirmationDelete] = useState(false);
  const [openConfirmationDuplicate, setOpenConfirmationDuplicate] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {updateMode && (
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenConfirmationDuplicate(true);
            }}
          >
            {translate.formatMessage({ id: "button.duplicate" })}
          </MenuItem>
        )}
        {updateMode && (
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenConfirmationDelete(true);
            }}
          >
            {translate.formatMessage({ id: "button.delete" })}
          </MenuItem>
        )}
      </Menu>
      <ConfirmationDelete
        open={openConfirmationDelete}
        setOpen={setOpenConfirmationDelete}
        entityName={values.name}
        onClickHandler={() => {
          deleteEstimation(user.token, values.id).then(() => {
            gaEventTracker("estimation_delete", " estimation delete");
            setOpenConfirmationDelete(false);
            if (updateMode) setUpdateMode(false);
            if (estimation !== null) setEstimation(null);
            if (isDuplicate) setIsDuplicate(false);
            setResetToNew(true);
          });
        }}
      />
      <ConfirmationDuplicate
        open={openConfirmationDuplicate}
        setOpen={setOpenConfirmationDuplicate}
        onClickHandler={() => {
          getEstimation(user.token, values.id).then((est) => {
            gaEventTracker("estimation_duplicate", " estimation duplicate");
            const newEst = est;
            newEst.id = 0;
            newEst.name = "";
            const newEstimateDetails = est.estimateDetails.map((ed) => {
              const newEd = ed;
              newEd.estimateId = 0;
              return newEd;
            });
            newEst.estimateDetails = newEstimateDetails;
            setEstimation(newEst);
            setUpdateMode(false);
            setIsDuplicate(true);
            setOpenConfirmationDuplicate(false);
          });
        }}
      />
    </>
  );
};

export default DotsMenu;
