import React from "react";
import { Parser } from "html-to-react";
import { useIntl } from "react-intl";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";

const ContactUsDialog = ({ open, setOpen, onClickHandler }) => {
  const translate = useIntl();
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent id="alert-dialog-title">
        {Parser().parse(translate.formatMessage({ id: "message.contactUs" }))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickHandler}>{translate.formatMessage({ id: "button.accept" })}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactUsDialog;
