import { TextField, Select, FormControl } from "@mui/material";
import styled from "@emotion/styled";
import { lighten, darken } from "@mui/system";

export const WrapperControlsLeftStyled = styled.div`
  @media (max-width: 600px) {
    margin-top: 0;
  }
  flex-direction: row;
  flex-basis: 1%;
  justify-content: flex-start;
  align-self: flex-start;
  align-items: center;
  display: flex;
  width: 100%;
  gap: 25px;
  margin-top: 35px;
`;

export const TextFieldStyled = styled(TextField)`
  @media (max-width: 900px) {
    width: 100%;
    margin-top: 20px;
  }
  width: 250px;
  margin-top: 10px;
`;

export const SelectStyled = styled(Select)`
  @media (max-width: 900px) {
    width: 100%;
    // margin-top: 20px;
  }
  width: 250px;
  // margin-top: 0;
`;

export const FormControlStyled = styled(FormControl)`
  @media (max-width: 900px) {
    margin-top: 20px;
  }
  margin-top: 10px;
`;

export const WrapperControlsRightStyled = styled.div`
  flex-direction: row;
  flex-basis: 1%;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: stretch;
  margin-bottom: 50px;
  display: flex;
  width: 100%;
  gap: 25px;
`;

export const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

export const GroupItems = styled.ul`
  padding: 0;
  flex-basis: 1%;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: stretch;
  margin-bottom: 50px;
  display: flex;
  width: 100%;
  gap: 25px;
`;
