import { DialogContent } from "@mui/material";
import styled from "@emotion/styled";

export const DialogContentStyled = styled(DialogContent)`
  @media (max-width: 600px) {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 5px 10px;
  }
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
`;
