import { DialogContent } from "@mui/material";
import styled from "@emotion/styled";

export const DialogContentStyled = styled(DialogContent)`
  @media (max-width: 600px) {
    margin: 0px;
    padding: 20px 15px 20px 15px;
  }
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
`;
