import { useCallback, useContext } from "react";
import Context from "contexts/langContext";

export default function useLang() {
  const {
    spanishMessages,
    portugueseMessages,
    englishMesagges,
    setLocale,
    locale,
    setMessagges,
    spanishCountries,
    portugueseCountries,
    englishCountries,
  } = useContext(Context);

  const setLanguage = useCallback((lenguaje) => {
    switch (lenguaje) {
      case "es":
        setMessagges(spanishMessages);
        setLocale("es");
        window.localStorage.setItem("lang-nonuser", "es");
        break;
      case "pt":
        setMessagges(portugueseMessages);
        setLocale("pt");
        window.localStorage.setItem("lang-nonuser", "pt");
        break;
      default:
        setMessagges(englishMesagges);
        setLocale("en");
        window.localStorage.setItem("lang-nonuser", "en");
    }
  });

  const getEstimatorTemplateLanguageText = useCallback((value) => {
    if (locale === "es") {
      return value.spanishText;
    }
    if (locale === "pt") {
      return value.portugueseText;
    }
    return value.englishText;
  });

  const getTextByLanguage = useCallback((esText, ptText, enText) => {
    if (locale === "es") {
      return esText;
    }
    if (locale === "pt") {
      return ptText;
    }
    return enText;
  });

  const getCountries = useCallback(() => {
    if (locale === "es") {
      return spanishCountries;
    }
    if (locale === "pt") {
      return portugueseCountries;
    }
    return englishCountries;
  });

  return {
    locale,
    setLanguage,
    getEstimatorTemplateLanguageText,
    getTextByLanguage,
    getCountries,
  };
}
