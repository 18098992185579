import React, { useEffect, useContext } from "react";
import { Field, useFormikContext } from "formik";
import ConvetToNumberType from "utils";
import { GetTotalsSubsection, GetTotalsRelated, SetValuesQuestionRow } from "components/InputDollarQuestion/helper";
import { SetValuesQuestionPercentRow } from "components/InputPercent/helper";
import GlobalContext from "contexts/GlobalContext";
import { TexFieldEstimatorStyled } from "components/QuestionRow/styles";

const InputDollarQuestion = ({ index, minValue, maxValue, disabled, onClick }) => {
  const { values, setFieldValue } = useFormikContext();
  const { completedGroups, setCompletedGroups } = useContext(GlobalContext);
  const { completedSteps, setCompletedSteps } = useContext(GlobalContext);
  const { nextActiveStep, setNextActiveStep } = useContext(GlobalContext);
  const { activeStep, setActiveStep } = useContext(GlobalContext);

  // Se dispara al modificar un valor de cotizacion
  useEffect(() => {
    let hasValidValue = false;
    const groupId = values[`qv_groupId_${index}`];

    if (values[`qv_dollarValue_${index}`] > 0) {
      hasValidValue = true;
      if (!completedGroups.includes(groupId)) {
        const newArray = [...completedGroups, groupId];
        setCompletedGroups(newArray);
        setNextActiveStep(newArray.length + 1);
      }
    } else {
      hasValidValue = Object.entries(values)
        .filter((x) => x[0].includes("qv_groupId_") && x[1] === groupId)
        .map((x) => x[0].split("_")[2])
        .some(
          (x) => !values[`qv_isTotal_${x}`] && (values[`qv_dollarValue_${x}`] > 0 || values[`qv_localValue_${x}`] > 0)
        );
    }

    if (!hasValidValue) {
      const newArray = completedGroups.filter((gId) => gId !== groupId);
      setCompletedGroups(newArray);
      if (nextActiveStep > 1 && activeStep < nextActiveStep) {
        // if (nextActiveStep > 1 && nextActiveStep !== groupId && nextActiveStep < activeStep) {
        setNextActiveStep(nextActiveStep - 1);
      }
    }
  }, [values[`qv_dollarValue_${index}`]]);

  return (
    <Field
      as={TexFieldEstimatorStyled}
      variant="standard"
      name={`qv_dollarValue_${index}`}
      type="number"
      disabled={disabled}
      inputProps={{
        min: minValue,
        max: maxValue,
      }}
      fontColor={values[`qv_colorChangeDollarValue_${index}`]}
      onClick={(option) => {
        onClick({ isDisabled: disabled });
      }}
      onFocus={(option) => {
        if (option.target.value === "0") option.target.select();
      }}
      onBlur={(option) => {
        let currentIndex = Number(option.target.name.split("_").slice(-1)[0]);
        if (values[`qv_dollarValue_${currentIndex}`] === "") {
          setFieldValue(`qv_dollarValue_${currentIndex}`, 0);
          setFieldValue(`qv_localValue_${currentIndex}`, 0);
        }
      }}
      onChange={(option) => {
        let currentIndex = Number(option.target.name.split("_").slice(-1)[0]);

        const newDollarValue =
          option.target.value !== "" ? ConvetToNumberType(option.target.value, minValue, maxValue, 2) : "";
        const newLocalValue = ConvetToNumberType(
          (values.dollarParity * newDollarValue).toString(),
          minValue,
          maxValue,
          2
        );

        SetValuesQuestionRow(setFieldValue, currentIndex, newDollarValue, newLocalValue, true);

        let { indexTotalSubsection, totalDollarValue, totalLocalValue } = GetTotalsSubsection(
          values,
          currentIndex,
          newDollarValue === "" ? 0 : newDollarValue,
          newLocalValue
        );
        setFieldValue(`qv_dollarValue_${indexTotalSubsection}`, totalDollarValue);
        setFieldValue(`qv_localValue_${indexTotalSubsection}`, totalLocalValue);

        let hasIndexRelated = values[`qv_subsectionIndexRelated_${currentIndex}`].length > 0;

        const totalValuesRelated = [
          {
            index: indexTotalSubsection,
            dollarValue: totalDollarValue,
            localValue: totalLocalValue,
          },
        ];
        while (hasIndexRelated) {
          const { subsectionIndexRelated, totalDollarValueRelated, totalLocalValueRelated } = GetTotalsRelated(
            values,
            currentIndex,
            totalDollarValue,
            totalLocalValue,
            totalValuesRelated,
            setFieldValue
          );
          setFieldValue(`qv_dollarValue_${subsectionIndexRelated}`, totalDollarValueRelated);
          setFieldValue(`qv_localValue_${subsectionIndexRelated}`, totalLocalValueRelated);

          totalValuesRelated.push({
            index: subsectionIndexRelated,
            dollarValue: totalDollarValueRelated,
            localValue: totalLocalValueRelated,
          });

          hasIndexRelated = values[`qv_subsectionIndexRelated_${currentIndex}`].length > 0;
          currentIndex = subsectionIndexRelated;
          totalDollarValue = totalDollarValueRelated;
          totalLocalValue = totalLocalValueRelated;
        }
      }}
    />
  );
};

export default InputDollarQuestion;
