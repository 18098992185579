import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { FormattedDate } from "react-intl";
import { URLs } from "services/settings";
import montserratRegular from "fonts/Montserrat/Montserrat-Regular.ttf";
import montserratBold from "fonts/Montserrat/Montserrat-Bold.ttf";
import montserratExtraBold from "fonts/Montserrat/Montserrat-ExtraBold.ttf";
import montserratMedium from "fonts/Montserrat/Montserrat-Medium.ttf";

Font.register({
  family: "Montserrat",
  fonts: [
    { src: montserratRegular },
    { src: montserratMedium, fontWeight: 500 },
    { src: montserratBold, fontWeight: 700 },
    { src: montserratExtraBold, fontWeight: 800 },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    fontFamily: "Montserrat",
    fontStyle: "normal",
  },

  header: {
    position: "relative",
    width: "100%",
    height: "82px",
  },
  headerImage: {
    width: "100%",
    height: "100%",
  },
  headerLogoWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "25%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: "96px",
    height: "50.5px",
  },
  headerTextWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "75%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerText: {
    fontWeight: 800,
    fontSize: "16.5px",
    textAlign: "center",
    color: "#FFFFFF",
  },

  infoReportWrapper: {
    width: "100%",
    height: "24.5px",
    display: "flex",
    borderColor: "rgba(217, 217, 217, 1)",
    borderWidth: "3px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  infoEstimationNumber: {
    fontWeight: 700,
    fontSize: "8.5px",
    color: "#2EAEC1",
    marginLeft: "15px",
  },
  infoDate: {
    fontWeight: 700,
    fontSize: "8.5px",
    color: "#000000",
    marginRight: "15px",
  },

  titleWrapper: {
    width: "100%",
    height: "45.5px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  title: {
    fontWeight: 700,
    fontSize: "10px",
    textAlign: "center",
    color: "#676767",
  },

  infoEstimation: {
    width: "100%",
    height: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingTop: "20px",
    paddingLeft: "63.7px",
  },
  inputWrapper: {
    position: "relative",
    width: "120px",
    height: "43px",
  },
  input: {
    width: "104px",
    height: "23px",
    marginRight: "10px",
    borderColor: "#0000003B",
    borderWidth: "1px",
    borderRadius: "4px",
    fontSize: "6.6px",
    paddingLeft: "10px",
    paddingTop: "7px",
    zIndex: -1,
  },
  inputLabelImporte: {
    postion: "absolute",
    width: "80px",
    top: "4px",
    left: "15px",
    paddingLeft: "2px",
    backgroundColor: "#FFFFFF",
    fontSize: "5.25px",
    color: "#00000099",
  },
  inputLabelCountry: {
    postion: "absolute",
    width: "60px",
    top: "4px",
    left: "15px",
    paddingLeft: "2px",
    backgroundColor: "#FFFFFF",
    fontSize: "5.25px",
    color: "#00000099",
  },
  inputLabelCity: {
    postion: "absolute",
    width: "50px",
    top: "4px",
    left: "15px",
    paddingLeft: "2px",
    backgroundColor: "#FFFFFF",
    fontSize: "5.25px",
    color: "#00000099",
  },
  inputLabelNombre: {
    postion: "absolute",
    width: "70px",
    top: "4px",
    left: "15px",
    paddingLeft: "2px",
    backgroundColor: "#FFFFFF",
    fontSize: "5.25px",
    color: "#00000099",
  },

  estimationsWrapper: {
    paddingLeft: "63.7px",
  },
  section: {
    width: "476.7px",
    height: "26.5px",
    paddingLeft: "6.62px",
    paddingTop: "6.62px",
    marginBottom: "4.55px",
    backgroundColor: "#F0F0F0",
    borderTopLeftRadius: "20px",
    borderTopRightRadiusRadius: "4px",
    borderBottomRightRadius: "20px",
    borderBottomLeftRadius: "0px",
    fontWeight: 700,
    fontSize: "9.93px",
    color: "#676767CC",
    marginTop: "20px",
  },
  subsection: {
    width: "476.7px",
    height: "23.17px",
    paddingLeft: "6.62px",
    borderBottomColor: "#0000001f",
    borderBottomWidth: "1px",
    display: "flex",
    flexDirection: "row",
    fontWeight: 500,
    fontSize: "6px",
  },
  subsectionTitle: {
    width: "377.38px",
    height: "23.17px",
    display: "flex",
    justifyContent: "center",
  },
  subsectionMonedaLocal: {
    width: "49.65px",
    height: "23.17px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subsectionUsd: {
    width: "49.65px",
    height: "23.17px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  question: {
    width: "476.7px",
    height: "23.17px",
    paddingLeft: "6.62px",
    borderBottomColor: "#0000001f",
    borderBottomWidth: "1px",
    display: "flex",
    flexDirection: "row",
    fontSize: "6px",
  },
  questionDescription: {
    width: "377.38px",
    height: "23.17px",
    display: "flex",
    justifyContent: "center",
  },
  localValue: {
    width: "49.65px",
    height: "23.17px",
    paddingLeft: "6.62px",
    display: "flex",
    justifyContent: "center",
  },
  usdValue: {
    width: "49.65px",
    height: "23.17px",
    paddingLeft: "6.62px",
    display: "flex",
    justifyContent: "center",
  },
  questionDescriptionPercent: {
    width: "327.63px",
    height: "23.17px",
    display: "flex",
    justifyContent: "center",
  },
  percentValue: {
    width: "49.65px",
    height: "23.17px",
    paddingLeft: "6.62px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#2eaec157",
  },
  total: {
    width: "476.7px",
    height: "23.17px",
    paddingLeft: "253.25px",
    display: "flex",
    flexDirection: "row",
    fontSize: "5.79px",
    marginBottom: "10px",
  },
  totalDescription: {
    width: "124.15px",
    height: "23.17px",
    paddingLeft: "6.62px",
    display: "flex",
    justifyContent: "center",
    fontWeight: 700,
    backgroundColor: "#d9d9d966",
  },
  totalLocalValue: {
    width: "49.65px",
    height: "23.17px",
    paddingLeft: "6.62px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#d9d9d966",
  },
  totalUsdValue: {
    width: "49.65px",
    height: "23.17px",
    paddingLeft: "6.62px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#d9d9d966",
  },

  footerWrapper: {
    width: "100%",
    paddingTop: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
  },
  titleFooter: {
    marginBottom: "19.44px",
    fontWeight: 700,
    fontSize: "9.93px",
    color: "#000000",
  },
  estimationResultsWrapper: {
    width: "100%",
    paddingTop: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
  },
  estimationResult: {
    width: "334.35px",
    height: "43px",
    marginTop: "4.14px",
    display: "flex",
    flexDirection: "column",
    borderWidth: "2px",
    borderColor: "#032350",
  },
  sectionResult: {
    marginLeft: "13.24px",
    marginTop: "5.79px",
    fontWeight: 700,
    fontSize: "9.93px",
    color: "#032350",
  },
  estimationSubtotal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  subtotalText: {
    fontWeight: 700,
    fontSize: "8.27px",
    color: "#676767",
    marginLeft: "13.24px",
  },
  subtotalValue: {
    fontWeight: 700,
    fontSize: "9.93px",
    color: "#2eaec1",
    marginRight: "3.31px",
  },
});
// #000000de
const PdfEstimator = ({ estimatorTemplate, estimation, translate, getEstimatorTemplateLanguageText, locale }) => {
  let totalSections = [];

  estimatorTemplate.groups.map((group, index) =>
    group.sections.map((section) => {
      totalSections.push({ sectionId: section.id, total: 0 });
      section.subsections.map((subsection) => {
        subsection.questions.map((question) => {
          if (question.isTotal) {
            const questionValue = estimation.estimateDetails.find((x) => x.questionId === question.id);
            totalSections[totalSections.length - 1].total = questionValue ? questionValue.dollarValue : 0;
          }
          return totalSections;
        });
        return null;
      });
      return null;
    })
  );
  debugger;
  const result = totalSections;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.headerImage} source={`${URLs.imgUrl}/HeaderPdf.png`} />
          <View style={styles.headerTextWrapper}>
            <Text style={styles.headerText}>{translate.formatMessage({ id: "app.title" })}</Text>
            <br />
            <Text style={styles.headerText}>{translate.formatMessage({ id: "report.titleline2" })}</Text>
          </View>
          <View style={styles.headerLogoWrapper}>
            <Image style={styles.logo} source={`${URLs.imgUrl}/Logo.png`} />
          </View>
        </View>
        <View style={styles.infoReportWrapper}>
          <Text style={styles.infoEstimationNumber}>
            {translate.formatMessage({ id: "report.estimationNumber" })} {estimation.id}
          </Text>
          <Text style={styles.infoDate}>
            {translate.formatMessage(
              { id: "formattedDate" },
              {
                weekday: new Date().toLocaleDateString(locale, { weekday: "long" }),
                day: new Date().getDate(),
                month: new Date().toLocaleDateString(locale, { month: "long" }),
                year: new Date().getFullYear(),
              }
            )}
          </Text>
        </View>
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>{translate.formatMessage({ id: "report.titleFotter" })}</Text>
        </View>
        <View style={styles.infoEstimation}>
          <View style={styles.inputWrapper}>
            <Text style={styles.inputLabelImporte}>{translate.formatMessage({ id: "input.exchangeRate" })}</Text>
            <View style={styles.input}>
              <Text>1 USD/ {estimation.dollarParity} </Text>
            </View>
          </View>
          <View style={styles.inputWrapper}>
            <Text style={styles.inputLabelCountry}>{translate.formatMessage({ id: "input.originCountry" })}</Text>
            <View style={styles.input}>
              <Text> {estimation.originCountry} </Text>
            </View>
          </View>
          <View style={styles.inputWrapper}>
            <Text style={styles.inputLabelCity}>{translate.formatMessage({ id: "input.originCity" })}</Text>
            <View style={styles.input}>
              <Text> {estimation.originCity} </Text>
            </View>
          </View>
          <View style={styles.inputWrapper}>
            <Text style={styles.inputLabelCountry}>{translate.formatMessage({ id: "input.destinationCountry" })}</Text>
            <View style={styles.input}>
              <Text> {estimation.destinationCountry} </Text>
            </View>
          </View>
        </View>
        <View style={styles.infoEstimation}>
          <View style={styles.inputWrapper}>
            <Text style={styles.inputLabelCity}>{translate.formatMessage({ id: "input.destinationCity" })}</Text>
            <View style={styles.input}>
              <Text> {estimation.destinationCity} </Text>
            </View>
          </View>
          <View style={styles.inputWrapper}>
            <Text style={styles.inputLabelCity}>{translate.formatMessage({ id: "input.article" })}</Text>
            <View style={styles.input}>
              <Text>{estimation.product}</Text>
            </View>
          </View>
          <View style={styles.inputWrapper}>
            <Text style={styles.inputLabelNombre}>{translate.formatMessage({ id: "input.transportType" })}</Text>
            <View style={styles.input}>
              <Text>
                {estimation.transportTypeCode !== ""
                  ? translate.formatMessage({ id: estimation.transportTypeCode })
                  : "-"}
              </Text>
            </View>
          </View>
          <View style={styles.inputWrapper}>
            <Text style={styles.inputLabelNombre}>{translate.formatMessage({ id: "input.name" })}</Text>
            <View style={styles.input}>
              <Text>{estimation.name}</Text>
            </View>
          </View>
        </View>
        <View style={styles.estimationResultsWrapper}>
          {estimatorTemplate.groups.map((group) =>
            group.sections.map((section) => {
              let hasAnyValue = false;
              section.subsections.map((subsection) => {
                subsection.questions.map((question) => {
                  if (!question.isTotal && !hasAnyValue) {
                    const questionValue = estimation.estimateDetails.find((x) => x.questionId === question.id);
                    let qv = questionValue ? questionValue.dollarValue : 0;
                    hasAnyValue = qv !== 0;
                  }
                  return totalSections;
                });
                return null;
              });

              return (
                hasAnyValue && (
                  <View style={styles.estimationResult}>
                    <View>
                      <Text style={styles.sectionResult}>{getEstimatorTemplateLanguageText(section)}</Text>
                    </View>
                    <View style={styles.estimationSubtotal}>
                      <Text style={styles.subtotalText}>Subtotal</Text>
                      <Text style={styles.subtotalValue}>
                        USD {totalSections.find((x) => x.sectionId === section.id).total.toFixed(2)}
                      </Text>
                    </View>
                  </View>
                )
              );
            })
          )}
        </View>
        <View style={styles.footerWrapper}>
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              backgroundColor: "#f0f0f0",
              paddingTop: "25px",
              paddingBottom: "50px",
            }}
          >
            <View
              style={{
                width: "334.35px",
              }}
            >
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <View style={{ width: "33%", paddingRight: "6px", paddingTop: "0" }}>
                  <View
                    style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                  >
                    <View style={{ textAlign: "justify", paddingTop: "15px" }}>
                      <Text style={{ fontSize: "5.79px" }}>{translate.formatMessage({ id: "info.section1" })}</Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: "33%",
                    paddingLeft: "6px",
                    paddingRight: "6px",
                    paddingTop: "0",
                    borderLeftColor: "Gray",
                    borderRightColor: "Gray",
                    borderLeftWidth: "1px",
                    borderRightWidth: "1px",
                  }}
                >
                  <View
                    style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                  >
                    <View style={{ textAlign: "justify", paddingTop: "15px" }}>
                      <Text style={{ fontSize: "5.79px" }}>{translate.formatMessage({ id: "info.section2" })}</Text>
                    </View>
                  </View>
                </View>
                <View style={{ width: "33%", paddingLeft: "6px", paddingTop: "0" }}>
                  <View
                    style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                  >
                    <View style={{ textAlign: "justify", paddingTop: "15px" }}>
                      <Text style={{ fontSize: "5.79px", marginBottom: "5px" }}>
                        {translate.formatMessage({ id: "reportinfo.section3_1" })}
                      </Text>
                      <Text style={{ fontSize: "5.1px", marginBottom: "5px", fontWeight: "bold", color: "blue" }}>
                        {translate.formatMessage({ id: "reportinfo.section3_2" })}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>{translate.formatMessage({ id: "report.subtitle" })}</Text>
        </View>
        <View style={styles.estimationsWrapper}>
          {estimatorTemplate.groups.map((group, index) =>
            group.sections.map((section) => {
              let hasAnyValue = false;
              section.subsections.map((subsection) => {
                subsection.questions.map((question) => {
                  if (!question.isTotal && !hasAnyValue) {
                    const questionValue = estimation.estimateDetails.find((x) => x.questionId === question.id);
                    let qv =
                      questionValue &&
                      ((question.isPercentage && questionValue.percentValue !== 0) || !question.isPercentage)
                        ? questionValue.dollarValue
                        : 0;
                    hasAnyValue =
                      (question.isPercentage &&
                        questionValue &&
                        questionValue.percentValue &&
                        questionValue.percentValue !== 0) ||
                      (!question.isPercentage && (qv !== 0 || (questionValue && questionValue.localValue !== 0)));
                  }
                  return null;
                });
                return null;
              });
              return (
                hasAnyValue && (
                  <View wrap={false}>
                    <View style={styles.section}>
                      <Text>{getEstimatorTemplateLanguageText(section)}</Text>
                    </View>
                    {section.subsections.map((subsection) => (
                      <>
                        <View style={styles.subsection}>
                          <View style={styles.subsectionTitle}>
                            {!subsection.hideTextTitle && <Text>{getEstimatorTemplateLanguageText(subsection)}</Text>}
                          </View>
                          <View style={styles.subsectionMonedaLocal}>
                            {!subsection.hideCurrencyTitle && (
                              <Text>{translate.formatMessage({ id: "title.localCurrency" })}</Text>
                            )}
                          </View>
                          <View style={styles.subsectionUsd}>{!subsection.hideCurrencyTitle && <Text>USD</Text>}</View>
                        </View>
                        {subsection.questions.map((question) => {
                          let questionValue = estimation.estimateDetails.find((x) => x.questionId === question.id);
                          if (question.isPercentage) {
                            return (
                              <View style={styles.question}>
                                <View style={styles.questionDescriptionPercent}>
                                  <Text>{getEstimatorTemplateLanguageText(question)}</Text>
                                </View>
                                <View style={styles.percentValue}>
                                  <Text>{questionValue ? questionValue.percentageValue.toFixed(2) : 0}%</Text>
                                </View>
                                <View style={styles.localValue}>
                                  <Text>{questionValue ? questionValue.localValue.toFixed(2) : 0}</Text>
                                </View>
                                <View style={styles.usdValue}>
                                  <Text>{questionValue ? questionValue.dollarValue.toFixed(2) : 0}</Text>
                                </View>
                              </View>
                            );
                          }
                          if (question.isTotal) {
                            return (
                              <View style={styles.total}>
                                <View style={styles.totalDescription}>
                                  <Text>{getEstimatorTemplateLanguageText(question)}</Text>
                                </View>
                                <View style={styles.localValue}>
                                  <Text>{questionValue ? questionValue.localValue.toFixed(2) : 0}</Text>
                                </View>
                                <View style={styles.usdValue}>
                                  <Text>{questionValue ? questionValue.dollarValue.toFixed(2) : 0}</Text>
                                </View>
                              </View>
                            );
                          }
                          return (
                            <View style={styles.question}>
                              <View style={styles.questionDescription}>
                                <Text>{getEstimatorTemplateLanguageText(question)}</Text>
                              </View>
                              <View style={styles.localValue}>
                                <Text>{questionValue ? questionValue.localValue : 0}</Text>
                              </View>
                              <View style={styles.usdValue}>
                                <Text>{questionValue ? questionValue.dollarValue : 0}</Text>
                              </View>
                            </View>
                          );
                        })}
                      </>
                    ))}
                  </View>
                )
              );
            })
          )}
        </View>
      </Page>
    </Document>
  );
};

export default PdfEstimator;
