import { SetValuesQuestionPercentRow } from "components/InputPercent/helper";

export const SetValuesQuestionRow = (setFieldValue, index, dollarValue, localValue, isFromDollarChange) => {
  setFieldValue(`qv_dollarValue_${index}`, dollarValue);
  setFieldValue(`qv_localValue_${index}`, localValue);

  setFieldValue(`qv_isChangeDollarValue_${index}`, isFromDollarChange);
  setFieldValue(`qv_isChangeLocalValue_${index}`, !isFromDollarChange);

  setFieldValue(`qv_colorChangeDollarValue_${index}`, isFromDollarChange ? "#2EAEC1" : "#000000d9");
  setFieldValue(`qv_colorChangeLocalValue_${index}`, isFromDollarChange ? "#000000d9" : "#2EAEC1");
};

export const GetTotalsSubsection = (values, index, newDollarValue, newLocalValue) => {
  const indexFirstQuestionSubsection = values[`qv_indexFirstQuestionSubsection_${index}`];
  const indexTotalSubsection = values[`qv_indexTotalSubsection_${index}`];
  let totalDollarValue = 0;
  let totalLocalValue = 0;
  for (let i = indexFirstQuestionSubsection; i < indexTotalSubsection; i++) {
    totalDollarValue += i === index ? newDollarValue : Number(values[`qv_dollarValue_${i}`]);
    totalLocalValue += i === index ? newLocalValue : Number(values[`qv_localValue_${i}`]);
  }

  if (values[`qv_subsectionOperation_indexTotalSubsection_${indexTotalSubsection}`] != null) {
    const ssoIndexTotalSubsection = values[`qv_subsectionOperation_indexTotalSubsection_${indexTotalSubsection}`];
    if (values[`qv_subsectionOperation_operationType_${indexTotalSubsection}`] === 0) {
      totalDollarValue += Number(values[`qv_dollarValue_${ssoIndexTotalSubsection}`]);
      totalLocalValue += Number(values[`qv_localValue_${ssoIndexTotalSubsection}`]);
    } else {
      totalDollarValue = Number(values[`qv_dollarValue_${ssoIndexTotalSubsection}`]) - totalDollarValue;
      totalLocalValue = Number(values[`qv_localValue_${ssoIndexTotalSubsection}`]) - totalLocalValue;
    }
  }

  return {
    indexTotalSubsection,
    totalDollarValue,
    totalLocalValue,
  };
};

export const GetTotalsRelated = (
  values,
  index,
  totalDollarValue,
  totalLocalValue,
  totalValuesRelated,
  setFieldValue
) => {
  const sectionId = values[`qv_sectionId_${index}`];
  let result = {};
  let newPercentValues = [];

  if (values[`qv_subsectionIndexRelated_${index}`] !== null) {
    values[`qv_subsectionIndexRelated_${index}`].forEach((indexRelated, x) => {
      let localValuePercentOld = 0;
      let dollarValuePercentOld = 0;
      if (values[`qv_isPercentage_${indexRelated}`] === true) {
        const ssoIndexTotalSubsection = values[`qv_subsectionOperation_indexTotalSubsection_${indexRelated}`];
        let totalValueRelated1 = totalValuesRelated.find((o) => o.index === ssoIndexTotalSubsection);
        localValuePercentOld = values[`qv_localValue_${indexRelated}`];
        dollarValuePercentOld = values[`qv_dollarValue_${indexRelated}`];
        const dollarValue = (values[`qv_percentageValue_${indexRelated}`] * totalValueRelated1.dollarValue) / 100;
        const localValue = (values[`qv_percentageValue_${indexRelated}`] * totalValueRelated1.localValue) / 100;

        SetValuesQuestionPercentRow(
          setFieldValue,
          indexRelated,
          values[`qv_percentageValue_${indexRelated}`],
          dollarValue,
          localValue
        );
        newPercentValues.push({ index: indexRelated, newDollarValue: dollarValue, newLocalValue: localValue });
      }

      const subsectionIndexRelated = indexRelated;
      const indexFirstQuestionSubsectionRelated = values[`qv_indexFirstQuestionSubsection_${subsectionIndexRelated}`];
      const indexTotalSubsectionRelated = values[`qv_indexTotalSubsection_${subsectionIndexRelated}`];
      const sectionIdRelated = values[`qv_sectionId_${indexTotalSubsectionRelated}`];
      let totalDollarValueRelated = 0;
      let totalLocalValueRelated = 0;
      if ((values[`qv_isPercentage_${indexRelated}`] === true && values[`qv_percentageValue_${indexRelated}`]) !== 0) {
        for (let i = indexFirstQuestionSubsectionRelated; i < indexTotalSubsectionRelated; i++) {
          if (newPercentValues.length > 0) {
            let percentValues = newPercentValues.find((o) => o.index === i);
            if (percentValues) {
              totalDollarValueRelated += Number(percentValues.newDollarValue);
              totalLocalValueRelated += Number(percentValues.newLocalValue);
            }
          } else {
            totalDollarValueRelated += Number(values[`qv_dollarValue_${i}`]);
            totalLocalValueRelated += Number(values[`qv_localValue_${i}`]);
          }
        }

        // if (sectionId === sectionIdRelated) {
        if (values[`qv_subsectionOperation_operationType_${subsectionIndexRelated}`] === 0) {
          totalDollarValueRelated = totalDollarValue + totalDollarValueRelated - dollarValuePercentOld;
          totalLocalValueRelated = totalLocalValue + totalLocalValueRelated - localValuePercentOld;
        } else {
          totalDollarValueRelated = totalDollarValue - totalDollarValueRelated - dollarValuePercentOld;
          totalLocalValueRelated = totalLocalValue - totalLocalValueRelated - localValuePercentOld;
        }
        // }
      }
      result = {
        subsectionIndexRelated,
        totalDollarValueRelated,
        totalLocalValueRelated,
      };
    });
  }
  return result;
};
