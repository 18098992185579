import ConvetToNumberType from "utils";

export const GetCurrencyValuesFromPercent = (values, index, percentageValue) => {
  const ssoIndexTotalSubsection = values[`qv_subsectionOperation_indexTotalSubsection_${index}`];
  const dollarValue = (percentageValue * values[`qv_dollarValue_${ssoIndexTotalSubsection}`]) / 100;
  const localValue = (percentageValue * values[`qv_localValue_${ssoIndexTotalSubsection}`]) / 100;

  return {
    dollarValue,
    localValue,
  };
};

export const SetValuesQuestionPercentRow = (setFieldValue, index, percentageValue, dollarValue, localValue) => {
  const maxMoneyValue = 999999999999;
  setFieldValue(`qv_percentageValue_${index}`, percentageValue);
  setFieldValue(`qv_dollarValue_${index}`, ConvetToNumberType(dollarValue.toString(), 0, maxMoneyValue, 2));
  setFieldValue(`qv_localValue_${index}`, ConvetToNumberType(localValue.toString(), 0, maxMoneyValue, 2));
};
