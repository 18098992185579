import React, { useEffect, useState, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIntl } from "react-intl";
import { Dialog } from "@mui/material";
import { DialogContentStyled } from "components/HistoryModal/style";
import BootstrapDialogTitle from "components/BootstrapDialogTitle";
import HistoryList from "components/HistoryList";
import getEstimations from "services/getEstimations";
import getEstimation from "services/getEstimation";
import useUser from "hooks/useUser";
import GlobalContext from "contexts/GlobalContext";
import SnackbarSuccess from "components/SnackbarSuccess";

const HistoryModal = ({ open, handleClose, setDisabledButton }) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const translate = useIntl();
  const { setUpdateMode, setEstimation, resetToNew, setResetToNew, isDuplicate, setIsDuplicate, setToUpdateMode } =
    useContext(GlobalContext);
  const { user } = useUser();
  const [isValueLoded, setIsValueLoaded] = useState(false);
  const [estimations, setEstimations] = useState([]);
  // const [messageSuccess, setMessageSuccess] = useState("");
  // const [openMessageSuccess, setOpenMessageSuccess] = useState(false);

  useEffect(() => {
    if (open) {
      getEstimations(user.token).then((res) => {
        setEstimations(res);
        setIsValueLoaded(true);
        setDisabledButton(false);
      });
    }
  }, [open]);

  return (
    <Dialog
      fullScreen={matchesSM}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="md"
      fullWidth={!matchesSM}
      style={{ padding: matchesSM ? "10px 5px" : "20px 15px" }}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} />
      <br />
      <DialogContentStyled>
        <strong>{translate.formatMessage({ id: "title.historicalEstimation" })}</strong>
        <br />
        <br />
        <HistoryList
          estimations={estimations}
          isValueLoded={isValueLoded}
          estimationDeletedSuccessHandler={() => {
            setIsValueLoaded(false);
            getEstimations(user.token).then((res) => {
              setEstimations(res);
              setIsValueLoaded(true);
            });
          }}
          onClickRowEditHandler={(id) => {
            getEstimation(user.token, id).then((est) => {
              if (resetToNew) setResetToNew(false);
              if (isDuplicate) setIsDuplicate(false);
              setUpdateMode(true);
              setToUpdateMode(true);
              setEstimation(est);
              handleClose();
            });
          }}
          estimationDuplictedSuccessHandler={() => {
            handleClose();
          }}
        />
      </DialogContentStyled>
    </Dialog>
  );
};

export default HistoryModal;
