import { URLs } from "./settings";

export default function getEstimation(jwt, id) {
  const apiURL = `${URLs.apiUrl}/Estimator/EstimationsValues/${id}`;
  return fetch(apiURL, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  })
    .then((res) => {
      if (!res.ok) throw new Error("Response is NOT ok");
      return res.json();
    })
    .then((res) => res);
}
