import styled from "@emotion/styled";

export const Background = styled.div`
  color: #fff;
  width: 100%;
  min-height: 367px;
  margin-top: 60px;
  background-position: center;
  background-size: cover;
  position: relative;

  @media (max-width: 900px) {
    margin-top: 30px;
    text-align: center;
    font-size: 22px;
    min-height: 320px;
  }
`;

export const Title = styled.div`
  position: absolute;
  top: 60px;
  left: 62px;
  font-weight: bold;
  font-size: 40px;
  font-weight: 800;
  line-height: 49px;
  @media (max-width: 900px) {
    left: 15px;
    font-size: 35px;
  }
  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 20px;
    left: 0;
    width: 100%;
  }
  @media (max-width: 360px) {
    font-size: 20px;
  }
`;

export const CarouselContainer = styled.div`
  position: absolute;
  width: 771px;
  height: 175px;
  top: 137px;
  left: 62px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  p {
    font-size: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
    left: 15px;
    line-height: 14px;
    & p {
      text-align: justify;
      margin-left: 0px;
      margin-right: 30px;
    }
  }
  @media (max-width: 600px) {
    top: 107px;
    left: 0px;
    & p {
      font-size: 14px;
      margin-left: 10px;
      margin-right: 20px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @media (max-width: 360px) {
    p {
      font-size: 12px;
    }
  }
`;
