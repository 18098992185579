import { URLs } from "./settings";

const fromApiResponseToEstimatorTemplate = (response) => {
  let indexQuestion = -1;
  response.groups = response.groups.map((group) => {
    group.sections = group.sections.map((section) => {
      section.subsections = section.subsections.map((subsection) => {
        subsection.questions = subsection.questions.map((question) => {
          indexQuestion += 1;
          return { ...question, index: indexQuestion };
        });
        return subsection;
      });
      return section;
    });
    return group;
  });
  return response;
};

export default function getEstimatorTemplate() {
  const apiURL = `${URLs.apiUrl}/estimatortemplate`;

  return fetch(apiURL)
    .then((res) => res.json())
    .then(fromApiResponseToEstimatorTemplate);
}
