import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import getAppMultilanguageText from "services/getAppMultilanguageText";
import useUser from "hooks/useUser";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import esLocale from "i18n-iso-countries/langs/es.json";
import ptLocale from "i18n-iso-countries/langs/pt.json";

const Context = React.createContext();

const generateLanguageMessages = (appMultilanguageText) => {
  let spanishMessages = {};
  let portugueseMessages = {};
  let englishMesagges = {};

  for (let i = 0; i < appMultilanguageText.length; i++) {
    spanishMessages[appMultilanguageText[i].code] = appMultilanguageText[i].spanishText;
    portugueseMessages[appMultilanguageText[i].code] = appMultilanguageText[i].portugueseText;
    englishMesagges[appMultilanguageText[i].code] = appMultilanguageText[i].englishText;
  }

  return { spanishMessages, portugueseMessages, englishMesagges };
};

export function LangProvider({ children }) {
  countries.registerLocale(enLocale);
  countries.registerLocale(esLocale);
  countries.registerLocale(ptLocale);
  const { isLogged, user } = useUser();
  const [spanishMessages, setSpanishMessages] = useState({});
  const [portugueseMessages, setPortugueseMessages] = useState({});
  const [englishMesagges, setEnglishMesagges] = useState({});
  const [languageSuccess, setLanguageSuccess] = useState(false);
  const [messagges, setMessagges] = useState();
  const [locale, setLocale] = useState();
  const [spanishCountries, setSpanishCountries] = useState({});
  const [portugueseCountries, setPortugueseCountries] = useState({});
  const [englishCountries, setEnglishCountries] = useState({});

  let lang = "en";

  const getCountries = (localeParam) => {
    countries.getNames(localeParam, { select: "official" });
    const countryObj = countries.getNames(localeParam);
    countryObj.X = "None";

    const countryList = Object.entries(countryObj).map(([key, value]) => {
      let country = {
        code: key,
        label: value,
      };
      return country;
    });

    return countryList;
  };

  const loadLanguage = () => {
    getAppMultilanguageText().then((result) => {
      result.push({
        code: "formattedDate",
        englishText: "{weekday}, {month} {day}, {year}",
        portugueseText: "{weekday}, {day} de {month} de {year}",
        spanishText: "{weekday} {day} de {month} de {year}",
      });
      const languagesMessages = generateLanguageMessages(result);

      if (isLogged && user.language) {
        lang = user.language;
      } else if (window.localStorage.getItem("lang-nonuser")) {
        lang = window.localStorage.getItem("lang-nonuser");
      }

      if (lang === "es") {
        setLocale("es");
        setMessagges(languagesMessages.spanishMessages);
      } else if (lang === "pt") {
        setLocale("pt");
        setMessagges(languagesMessages.portugueseMessages);
      } else {
        setLocale("en");
        setMessagges(languagesMessages.englishMesagges);
      }

      setSpanishMessages(languagesMessages.spanishMessages);
      setPortugueseMessages(languagesMessages.portugueseMessages);
      setEnglishMesagges(languagesMessages.englishMesagges);
      setLanguageSuccess(true);
    });

    setSpanishCountries(getCountries("es"));
    setPortugueseCountries(getCountries("pt"));
    setEnglishCountries(getCountries("en"));
  };

  useEffect(() => {
    loadLanguage();
  }, []);

  useEffect(() => {
    if (isLogged) loadLanguage();
  }, [isLogged]);

  return (
    languageSuccess && (
      <Context.Provider
        value={{
          spanishMessages,
          portugueseMessages,
          englishMesagges,
          setLocale,
          locale,
          setMessagges,
          spanishCountries,
          portugueseCountries,
          englishCountries,
        }}
      >
        <IntlProvider locale={locale} messages={messagges}>
          {children}
        </IntlProvider>
      </Context.Provider>
    )
  );
}

export default Context;
