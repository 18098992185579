import { Grid, FormControlLabel } from "@mui/material";
import styled from "@emotion/styled";
import ButtonContained from "components/ButtonContained";

export const GridContentStyled = styled(Grid)`
  padding-top: "5px";
  padding-right: "32px";
  padding-bottom: "32px";
  padding-left: "32px";
`;

export const GridTitleStyled = styled(Grid)`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const GridInputStyled = styled(Grid)`
  margin-bottom: 25px;
  width: 80%;
`;

export const ButtonContainedStyled = styled(ButtonContained)`
  margin-bottom: 50px;
`;
