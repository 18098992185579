import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { URLs } from "services/settings";
import useUser from "hooks/useUser";

const UserInfo = () => {
  const { user, logout } = useUser();
  const translate = useIntl();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `${URLs.jsUrl}/user-info.js`;
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="user_info">
      <div id="header-user-actions" className="user-menu">
        <div className="picture" />
        <div className="name">
          <span>{`${user.firstName} ${user.lastName}`}</span>
        </div>
        <ul className="menu">
          <li className="shared">
            <a href="/profile" className="profile">
              {translate.formatMessage({ id: "userMenu.yourProfile" })}
            </a>
            <a href="/user/profile" className="edit">
              {translate.formatMessage({ id: "userMenu.yourProfile" })}
            </a>
          </li>
          <li className="shared">
            <a href="/messages/list" className="inbox">
              {translate.formatMessage({ id: "userMenu.inbox" })}
            </a>
          </li>
          <li>
            <a href="/user/profile#/settings">{translate.formatMessage({ id: "userMenu.accountSettings" })}</a>
          </li>
          <li className="last">
            <a
              href="#"
              onClick={(e) => {
                logout();
                e.preventDefault();
              }}
            >
              {translate.formatMessage({ id: "userMenu.logout" })}
            </a>
          </li>
        </ul>
      </div>
      {/* <div className="notifications">
        <a
          className="notifications-btn"
          href="#"
          //   onclick="return false;"
        >
          <span style={{ display: "none" }}>0 </span>
        </a>
        <div className="notifications-info">
          <div className="notifications-content">
            <div className="no-notifications">You have no notifications</div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default UserInfo;
