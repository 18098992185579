export default function ConvetToNumberType(value, minValue, maxValue, precision) {
  if (!isNaN(value)) {
    let newNumber = 0;
    const hasDecimalSection = value.split(".").length === 2;
    if (hasDecimalSection) {
      const valueArray = value.split(".");
      const decimalSection = valueArray[1].length > precision ? valueArray[1].substring(0, precision) : valueArray[1];
      newNumber = Number(`${valueArray[0]}.${decimalSection}`);
    } else {
      newNumber = Number(value);
    }

    if (newNumber > maxValue) return maxValue;
    if (newNumber < minValue) return minValue;

    return newNumber;
  }
  return 0;
}
