import React from "react";
import { Route, Switch } from "wouter";
import ReactGA from "react-ga4";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid, Container } from "@mui/material";
import { UserProvider } from "contexts/UserContext";
import { LangProvider } from "contexts/langContext";
import { GlobalProvider } from "contexts/GlobalContext";
import { StaticDataProvider } from "contexts/StaticDataContext";
import { URLs } from "services/settings";
import Navbar from "components/Navbar";
import Header from "components/Header";
import Info from "components/Info";
import InfoDesktop from "components/InfoDesktop";
import Footer from "components/Footer";
import Estimator from "pages/Estimator";

const CustomTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body2: "span",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#75AAD4",
    },
    text: {
      primary: "#000.",
    },
  },
  typography: {
    body2: {
      fontFamily: "Roboto",
      fontStyle: "Regular",
      fontSize: "14px",
      lineHeight: "52px",
      align: "left",
      verticalAlign: "Top",
      letterSpacing: "0.17px",
      fontWeight: 400,
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
});

ReactGA.initialize(URLs.gaTrackingId);
// ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <ThemeProvider theme={CustomTheme}>
      <UserProvider>
        <LangProvider>
          <Navbar />
          <Grid container direction="column" justifyContent="space-evenly" alignItems="center">
            <Header />
            <Container maxWidth="xl">
              <GlobalProvider>
                <StaticDataProvider>
                  <Switch>
                    <Route component={Estimator} path="/" />
                  </Switch>
                </StaticDataProvider>
              </GlobalProvider>
            </Container>
            {matchesSM ? <Info /> : <InfoDesktop />}
            <Footer />
          </Grid>
        </LangProvider>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
