import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { URLs } from "services/settings";
import useUser from "hooks/useUser";
import useLang from "hooks/useLang";
import LoginNavbar from "components/LoginNavbar";
import UserInfo from "components/UserInfo";
import LoginModal from "components/LoginModal";
import ContactUsDialog from "components/ContactUsDialog";
import "components/Navbar/style.css";

const Navbar = () => {
  const { isLogged, user, logout } = useUser();
  const { setLanguage, locale } = useLang();
  const translate = useIntl();
  const [openContactUsDialog, setOpenContactUsDialog] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  useEffect(() => {
    if (!document.getElementById("script1")) {
      const script1 = document.createElement("script");
      script1.id = "script1";
      script1.src = `${URLs.jsUrl}/responsive_header.js?rpns3o`;
      script1.async = true;
      script1.crossOrigin = "anonymous";
      document.body.appendChild(script1);
    }

    if (!document.getElementById("script2")) {
      const script2 = document.createElement("script");
      script2.id = "script2";
      script2.src = `${URLs.jsUrl}/ca_header_front.js?rpns3o`;
      script2.async = true;
      script2.crossOrigin = "anonymous";
      document.body.appendChild(script2);
    }

    if (!document.getElementById("script3")) {
      const script3 = document.createElement("script");
      script3.id = "script3";
      script3.src = `${URLs.jsUrl}/ca_menu_desktop.js?rpns3o`;
      script3.async = true;
      script3.crossOrigin = "anonymous";
      document.body.appendChild(script3);
    }
    return () => {
      // document.body.removeChild(script1);
      // document.body.removeChild(script2);
      // document.body.removeChild(script3);
    };
  }, []);

  return (
    <>
      <LoginModal open={openLoginModal} handleClose={() => setOpenLoginModal(false)} />
      <div id="fixed-menu" className="scroll-animate darkHeader">
        {/* NAVBAR LEFT */}
        <div className="navbar-left">
          <div id="logo-wrapper">
            <a id="logo" href="/" aria-label="Menu" />
          </div>
        </div>
        {/* END NAVBAR LEFT */}

        {/* NAVBAR RIGHT */}
        <div className="navbar-right">
          <div id="user-menu" className="element-middle" style={{ display: "flex" }}>
            <div>
              <a id="submenu-search" href={translate.formatMessage({ id: "link.search" })}>
                <span>{translate.formatMessage({ id: "button.search" })}</span>
              </a>
            </div>
            {isLogged ? <UserInfo /> : <LoginNavbar />}
          </div>
          {/* End of #user-menu */}
          <div id="help-menu-wrapper" className="element-middle">
            <div id="help-menu">
              <a className="menu-button" />
              <ul className="menu">
                <li>
                  <a href={translate.formatMessage({ id: "link.helpCenter" })} target="_blank" rel="noreferrer">
                    {translate.formatMessage({ id: "button.helpCenter" })}
                  </a>
                </li>
                <li className="last">
                  <a
                    href="/"
                    title={translate.formatMessage({ id: "button.contactUs" })}
                    className="active md-trigger md-setperspective"
                    onClick={(e) => {
                      setOpenContactUsDialog(true);
                      e.preventDefault();
                    }}
                  >
                    {translate.formatMessage({ id: "button.contactUs" })}
                  </a>
                </li>
              </ul>
            </div>
          </div>{" "}
          {/* End of #help-menu-wrapper */}
          {!isLogged && (
            <div id="ca-lang-selector" className="element-middle">
              <div className="current">
                <span>{locale}</span>
              </div>

              <ul className="menu">
                {locale !== "pt" && (
                  <li>
                    <a
                      href="/"
                      data-code="pt"
                      onClick={(e) => {
                        setLanguage("pt");
                        e.preventDefault();
                      }}
                    >
                      pt
                    </a>
                  </li>
                )}
                {locale !== "es" && (
                  <li>
                    <a
                      href="/"
                      data-code="es"
                      onClick={(e) => {
                        setLanguage("es");
                        e.preventDefault();
                      }}
                    >
                      es
                    </a>
                  </li>
                )}
                {locale !== "en" && (
                  <li>
                    <a
                      href="/"
                      data-code="en"
                      onClick={(e) => {
                        setLanguage("en");
                        e.preventDefault();
                      }}
                    >
                      en
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}
          {/* end ca-lang-selector */}
        </div>
        {/* END NAVBAR RIGHT */}
        <div className="mobilehamburguer" id="submenumobilehamb">
          <span className="sandwich" />
        </div>
        <div className="sub" id="submenumobile">
          {isLogged && (
            <div className="block-row user-info">
              <div className="user-picture">
                <span className="no-picture" />
              </div>
              <div className="user-name">
                <a href="/profile">{`${user.firstName} ${user.lastName}`}</a>
              </div>
            </div>
          )}
          <ul className="ulsub">
            <li>
              <a href={translate.formatMessage({ id: "link.search" })}>
                <span>{translate.formatMessage({ id: "button.search" })}</span>
              </a>
            </li>
            {!isLogged && (
              <>
                <li>
                  <a
                    className="signup"
                    href={URLs.registerUserUrl}
                    title={translate.formatMessage({ id: "button.join" })}
                  >
                    {translate.formatMessage({ id: "button.join" })}
                  </a>
                </li>
                <li>
                  <a
                    className="login"
                    id="loginBtn"
                    title={translate.formatMessage({ id: "button.signin" })}
                    href="#"
                    onClick={(e) => {
                      setOpenLoginModal(true);
                      e.preventDefault();
                    }}
                  >
                    {translate.formatMessage({ id: "button.signin" })}
                  </a>
                </li>
              </>
            )}
            {isLogged && (
              <li>
                <a
                  className="logout"
                  id="loginBtn"
                  title={translate.formatMessage({ id: "userMenu.logout" })}
                  href="#"
                  onClick={(e) => {
                    logout();
                    e.preventDefault();
                  }}
                >
                  {translate.formatMessage({ id: "userMenu.logout" })}
                </a>
              </li>
            )}
          </ul>
          {!isLogged && (
            <div id="ca-lang-selector" className="element-middle">
              <div className="current">
                <span>{locale}</span>
              </div>
              <ul className="menu">
                {locale !== "pt" && (
                  <li>
                    <a
                      href="/"
                      data-code="pt"
                      onClick={(e) => {
                        setLanguage("pt");
                        e.preventDefault();
                      }}
                    >
                      pt
                    </a>
                  </li>
                )}
                {locale !== "es" && (
                  <li>
                    <a
                      href="/"
                      data-code="es"
                      onClick={(e) => {
                        setLanguage("es");
                        e.preventDefault();
                      }}
                    >
                      es
                    </a>
                  </li>
                )}
                {locale !== "en" && (
                  <li>
                    <a
                      href="/"
                      data-code="en"
                      onClick={(e) => {
                        setLanguage("en");
                        e.preventDefault();
                      }}
                    >
                      en
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
      <ContactUsDialog
        open={openContactUsDialog}
        setOpen={setOpenContactUsDialog}
        onClickHandler={() => {
          setOpenContactUsDialog(false);
        }}
      />
    </>
  );
};

export default Navbar;
