import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Carousel from "react-material-ui-carousel";
import { Parser } from "html-to-react";
import { Background, Title, CarouselContainer } from "components/Header/style";
import rectangle151 from "images/rectangle151.jpg";

export default function Header() {
  const translate = useIntl();
  const items = [
    {
      code: "carrousel.text.1",
    },
    {
      code: "carrousel.text.2",
    },
    {
      code: "carrousel.text.3",
    },
  ];

  return (
    <Background style={{ backgroundImage: `url(${rectangle151})` }}>
      <Title>
        <FormattedMessage id="app.title" defaultMessage="Titulo" />
      </Title>
      <CarouselContainer>
        <Carousel
          interval="15000"
          indicatorContainerProps={{
            style: {
              marginTop: "30px",
            },
          }}
        >
          {items.map((item, i) => (
            <div key={item.code}>{Parser().parse(translate.formatMessage({ id: item.code }))}</div>
          ))}
        </Carousel>
      </CarouselContainer>
    </Background>
  );
}
