import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Dialog, DialogContent, TextField, Autocomplete, CircularProgress, Select, MenuItem } from "@mui/material";
import { Field, useFormikContext } from "formik";
import getProduct from "services/getProduct";
import useStaticData from "hooks/useStaticData";
import useLang from "hooks/useLang";
import CountrySelect from "components/CountrySelect";
import BootstrapDialogTitle from "components/BootstrapDialogTitle";
import { GridContentStyled, GridTitleStyled, GridInputStyled, ButtonContainedStyled } from "components/AddModal/style";

const AddModal = ({ open, handleClose }) => {
  const translate = useIntl();
  const { locale } = useLang();
  const { values, setFieldValue, submitForm } = useFormikContext();
  const { getTransportTypes } = useStaticData();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      disablePortal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} />
      <DialogContent>
        <GridContentStyled container direction="colum" justifyContent="center" alignItems="center">
          <GridTitleStyled item>
            <strong>{translate.formatMessage({ id: "title.addEstimation" })}</strong>
          </GridTitleStyled>
          <GridInputStyled item>
            <Field
              as={TextField}
              label={translate.formatMessage({ id: "input.name" })}
              fullWidth
              variant="standard"
              id="name"
              name="name"
            />
          </GridInputStyled>
          <GridInputStyled item>
            <CountrySelect
              labelTitle={translate.formatMessage({ id: "input.originCountry" })}
              variant="standard"
              fullWidth
              componentName="addOriginCountry"
              fieldNameValue="addOriginCountry"
              fieldNames={["originCountry", "addOriginCountry"]}
            />
          </GridInputStyled>
          <GridInputStyled item>
            <Field
              as={TextField}
              label={translate.formatMessage({ id: "input.originCity" })}
              fullWidth
              variant="standard"
              id="addOriginCity"
              name="addOriginCity"
              onChange={(option) => {
                setFieldValue(`originCity`, option.target.value);
                setFieldValue(`addOriginCity`, option.target.value);
              }}
            />
          </GridInputStyled>
          <GridInputStyled item>
            <CountrySelect
              labelTitle={translate.formatMessage({ id: "input.destinationCountry" })}
              variant="standard"
              fullWidth
              componentName="addDestinationCountry"
              fieldNameValue="addDestinationCountry"
              fieldNames={["destinationCountry", "addDestinationCountry"]}
            />
          </GridInputStyled>
          <GridInputStyled item>
            <Field
              as={TextField}
              label={translate.formatMessage({ id: "input.destinationCity" })}
              fullWidth
              variant="standard"
              id="addDestinationCity"
              name="addDestinationCity"
              onChange={(option) => {
                setFieldValue(`destinationCity`, option.target.value);
                setFieldValue(`addDestinationCity`, option.target.value);
              }}
            />
          </GridInputStyled>
          <GridInputStyled item>
            <Autocomplete
              freeSolo
              inputValue={values.article}
              options={options}
              groupBy={(option) => option.parent}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate.formatMessage({ id: "input.article" })}
                  variant="standard"
                  placeholder={translate.formatMessage({ id: "placeholder.addArticle" })}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              onInputChange={(e) => {
                if (e !== null && e.target.value.length >= 3) {
                  setLoading(true);
                  getProduct(e.target.value, locale, 0, 1000).then((productsList) => {
                    let products = productsList.map((p) => ({
                      product: p.name, // `${p.parent}- ${p.name}`,
                      parent: p.parent,
                    }));
                    setOptions(products);
                    setLoading(false);
                  });
                  setFieldValue(`article`, e.target.value);
                  setFieldValue(`addArticle`, e.target.value);
                  setFieldValue(`isManualProductLoading`, true);
                  setFieldValue(`addIsManualProductLoading`, true);
                }
              }}
              onChange={(event, newValue) => {
                setFieldValue(`article`, newValue);
                setFieldValue(`addArticle`, newValue);
                setFieldValue(`isManualProductLoading`, false);
                setFieldValue(`addIsManualProductLoading`, false);
              }}
              getOptionLabel={(option) => option.product}
              loading={loading}
              fullWidth
            />
          </GridInputStyled>
          <GridInputStyled item>
            <Select
              labelId="transport-type-label"
              label={translate.formatMessage({ id: "input.transportType" })}
              value={values.addTransportTypeCode}
              variant="standard"
              fullWidth
              style={{ height: "48px" }}
              onChange={(option) => {
                setFieldValue(`transportTypeCode`, option.target.value);
                setFieldValue(`addTransportTypeCode`, option.target.value);
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {getTransportTypes().map((tt) => (
                <MenuItem value={tt.code}>{tt.description}</MenuItem>
              ))}
            </Select>
          </GridInputStyled>
          <GridInputStyled item>
            <ButtonContainedStyled
              componentwidth="100%"
              text={translate.formatMessage({ id: "button.save" })}
              onClick={() => {
                submitForm();
              }}
            />
          </GridInputStyled>
        </GridContentStyled>
      </DialogContent>
    </Dialog>
  );
};

export default AddModal;
