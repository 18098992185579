import { TextField } from "@mui/material";
import styled from "@emotion/styled";

export const TextFieldStyled = styled(TextField)`
  @media (max-width: 900px) {
    width: 100%;
    margin-top: 20px;
  }
  width: 250px;
  margin-top: 10px;
`;
